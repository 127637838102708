// Custom errors

export class ApiError extends Error {
    name = 'ApiError';

    constructor(error) {
        super(error?.message || 'Request failed');

        this.causedBy = error;

        const { status, data } = error?.response || {};
        this.statusCode = status || 500;
        this.isForbidden = this.statusCode === 403;

        const { request_id, correlation_id } = data?.context || {};
        this.requestId = request_id || '';
        this.correlationId = correlation_id || '';

        const { code, info, system, details } = data?.error || {};
        this.code = code || '';
        this.info = info || '';
        this.system = system || '';
        this.details = details || null;

        this.isTimeout = this.code === 'TIMEOUT';
        this.isBadRequest = this.code === 'BAD_REQUEST';

        this.isValidationError =
            this.isBadRequest && this.info === 'VALIDATION_ERROR';

        this.isEntityNotFound =
            this.isBadRequest && this.details?.info === 'ENTITY_NOT_FOUND';
    }
}
