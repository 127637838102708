// Loyalty actions

import * as types from './actionTypes';
import * as consumerApi from '../../services/consumerApi';
import { dialogClose, dialogError, dialogLoading } from './dialogActions';
import { notifyError, notifySuccess } from './notificationActions';

const TIER_RECALC_TIMEOUT_SEC = 30;

function getMarket(state) {
    return state?.auth?.user?.market;
}

export function loyaltyReset() {
    return { type: types.LOYALTY_RESET };
}

export function loyaltySetBrand(brand) {
    return { type: types.LOYALTY_SET_BRAND, brand };
}

export function loyaltySetTab(tab) {
    return { type: types.LOYALTY_SET_TAB, tab };
}

export function loyaltySetSummaryLoading() {
    return { type: types.LOYALTY_SET_SUMMARY_LOADING };
}

export function loyaltySetSummaryData(payload) {
    return { type: types.LOYALTY_SET_SUMMARY_DATA, payload };
}

export function loyaltySetSummaryError(error) {
    return { type: types.LOYALTY_SET_SUMMARY_ERROR, error };
}

export function loyaltySetTransLoading(page, rowsPerPage) {
    return {
        type: types.LOYALTY_SET_TRANS_LOADING,
        payload: { page, rowsPerPage },
    };
}

export function loyaltySetTransData(payload) {
    return { type: types.LOYALTY_SET_TRANS_DATA, payload };
}

export function loyaltySetTransError(error) {
    return { type: types.LOYALTY_SET_TRANS_ERROR, error };
}

export function loyaltySetAuditLoading(page, rowsPerPage) {
    return {
        type: types.LOYALTY_SET_AUDIT_LOADING,
        payload: { page, rowsPerPage },
    };
}

export function loyaltySetAuditData(payload) {
    return { type: types.LOYALTY_SET_AUDIT_DATA, payload };
}

export function loyaltySetAuditError(error) {
    return { type: types.LOYALTY_SET_AUDIT_ERROR, error };
}

export function loyaltySetError(error) {
    return { type: types.LOYALTY_SET_ERROR, error };
}

export function loyaltySetRecalcLoading() {
    return { type: types.LOYALTY_SET_RECALC_LOADING };
}

export function loyaltySetRecalcData(payload) {
    return { type: types.LOYALTY_SET_RECALC_DATA, payload };
}

export function loyaltyDataInit(consumerId, brand) {
    return function (dispatch) {
        return Promise.resolve()
            .then(() => dispatch(loyaltySetBrand(brand)))
            .then(() => dispatch(getLoyaltySummary(consumerId, brand)));
    };
}

export function getLoyaltySummary(consumerId, brand) {
    return function (dispatch, getState) {
        const country = getMarket(getState());
        return Promise.resolve()
            .then(() => dispatch(loyaltySetSummaryLoading()))
            .then(() => consumerApi.loyaltySummary(country, brand, consumerId))
            .then((result) => dispatch(loyaltySetSummaryData(result.data)))
            .catch((error) => dispatch(loyaltySetSummaryError(error)));
    };
}

export function getLoyaltyTransactions(consumerId, brand, page, rowsPerPage) {
    return function (dispatch, getState) {
        const country = getMarket(getState());
        return Promise.resolve()
            .then(() => dispatch(loyaltySetTransLoading(page, rowsPerPage)))
            .then(() =>
                consumerApi.loyaltyTransactions(
                    country,
                    brand,
                    consumerId,
                    page,
                    rowsPerPage
                )
            )
            .then((result) => dispatch(loyaltySetTransData(result)))
            .catch((error) => dispatch(loyaltySetTransError(error)));
    };
}

export function getLoyaltyAuditlog(consumerId, brand, page, rowsPerPage) {
    return function (dispatch, getState) {
        const country = getMarket(getState());
        return Promise.resolve()
            .then(() => dispatch(loyaltySetAuditLoading(page, rowsPerPage)))
            .then(() =>
                consumerApi.loyaltyLogs(
                    country,
                    brand,
                    consumerId,
                    page,
                    rowsPerPage
                )
            )
            .then((result) => dispatch(loyaltySetAuditData(result)))
            .catch((error) => dispatch(loyaltySetAuditError(error)));
    };
}

export function loyaltyAddPoints(consumerId, brand, points, remark) {
    return function (dispatch, getState) {
        const country = getMarket(getState());
        return Promise.resolve()
            .then(() => dispatch(dialogLoading()))
            .then(() =>
                consumerApi.loyaltyPoints(
                    country,
                    brand,
                    consumerId,
                    points,
                    remark
                )
            )
            .then(() => {
                dispatch(dialogClose());
                dispatch(notifySuccess((L) => L.msg_loyalty_add_points_ok));
                dispatch(getLoyaltySummary(consumerId, brand));
            })
            .catch((error) => {
                dispatch(dialogError(error));
                dispatch(notifyError((L) => L.msg_loyalty_add_points_error));
                dispatch(loyaltySetError(error));
            });
    };
}

export function loyaltyRecalcTier(consumerId, brand) {
    return function (dispatch, getState) {
        const country = getMarket(getState());
        return Promise.resolve()
            .then(() => dispatch(loyaltySetRecalcLoading()))
            .then(() =>
                consumerApi.loyaltyRecalcTier(country, brand, consumerId)
            )
            .then(() => {
                dispatch(notifySuccess((L) => L.msg_loyalty_recalc_request_ok));
                dispatch(loyaltySetRecalcData(true));
                dispatch(loyaltyRecalcTierTimeout(TIER_RECALC_TIMEOUT_SEC));
            })
            .catch((error) => {
                dispatch(
                    notifyError((L) => L.msg_loyalty_recalc_request_error)
                );
                dispatch(loyaltySetRecalcData(false));
                dispatch(loyaltySetError(error));
            });
    };
}

export function loyaltyRecalcTierTimeout(sec) {
    return function (dispatch) {
        return Promise.resolve()
            .then(
                () => new Promise((resolve) => setTimeout(resolve, sec * 1000))
            )
            .then(() => dispatch(loyaltySetRecalcData(false)));
    };
}
