import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Stack,
    Tab,
    Tabs,
} from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';

import { useMessages } from '../../../hooks/useMessages';
import { dialogShowCustom } from '../../../store/actions/dialogActions';
import {
    getLoyaltyAuditlog,
    getLoyaltySummary,
    getLoyaltyTransactions,
    loyaltyDataInit,
    loyaltyAddPoints,
    loyaltySetTab,
} from '../../../store/actions/loyaltyActions';

import ErrorIndicator from '../../common/ErrorIndicator';
import ProgressIndicator from '../../common/ProgressIndicator';
import { FIELDS as F } from '../../../services/consumerFields';
import * as DM from '../../../services/dataModel';
import * as C from '../../../config/marketConfig';

import { getTabFields } from './fields/fieldsTabLoyalty';
import AssignPointsDialog from './loyalty/AssignPointsDialog';

import ActionBar from './ActionBar';
import ActionButton from './ActionButton';
import FieldSection from './FieldSection';
import TableSection from './TableSection';

const TAB_DATA_GETTERS = {
    transaction: getLoyaltyTransactions,
    auditlog: getLoyaltyAuditlog,
};

function RefreshButton({ disabled, onClick }) {
    return (
        <IconButton sx={{ mr: 1 }} disabled={!!disabled} onClick={onClick}>
            <ReplayIcon />
        </IconButton>
    );
}

function SummaryError({ error, onRetry }) {
    const { L } = useMessages();
    const { isEntityNotFound } = error;

    return (
        <>
            {isEntityNotFound && (
                <ErrorIndicator
                    type="info"
                    message={L.consumerpage_loyalty_msg_consumer_not_registered}
                    retry={L.err_refresh}
                    onRetry={onRetry}
                />
            )}
            {!isEntityNotFound && (
                <ErrorIndicator
                    type="error"
                    message={L.err_error_fetching_data}
                    retry={L.err_retry}
                    onRetry={onRetry}
                />
            )}
        </>
    );
}

const TabLoyalty = ({ model }) => {
    const dispatch = useDispatch();
    const { L } = useMessages();

    const { user } = useSelector((state) => state.auth);
    const { market, hasConsumerUpdate } = user || {};

    const { brand, tab, summaryData, transactionData, auditData } = useSelector(
        (state) => state.loyalty
    );

    const marketBrands = useMemo(
        () => C.MV(C.MARKET_VALUES_LOYALTY_BRANDS, market),
        [market]
    );

    const summarySection = useMemo(
        () => getTabFields('summary', L, market),
        [L, market]
    );

    const tabSection = useMemo(
        () => getTabFields(tab, L, market),
        [tab, L, market]
    );

    const tabModel = useMemo(() => {
        const map = {
            transaction: transactionData,
            auditlog: auditData,
        };
        return map[tab];
    }, [tab, transactionData, auditData]);

    const UID = DM.getValue(model, F.UID);
    const isActive = DM.getValue(model, F.IS_ACTIVE_EX);
    const isDeleted = DM.getValue(model, F.IS_DELETED);

    const hasBrand = !!brand;
    const hasSummaryData = !!summaryData?.data;
    const canAssign =
        hasConsumerUpdate &&
        hasBrand &&
        hasSummaryData &&
        isActive &&
        !isDeleted;

    const tabModelNeedInit =
        hasSummaryData &&
        !tabModel?.loading &&
        !tabModel?.error &&
        !tabModel?.data;

    const handleAssignPoints = () => {
        dispatch(
            dialogShowCustom(AssignPointsDialog, (dialogModel) => {
                const { points, remark } = DM.getChangesNormalized(dialogModel);
                dispatch(loyaltyAddPoints(UID, brand, +points, remark));
            })
        );
    };

    const handleBrandChange = (event) => {
        const brand = event.target.value;
        dispatch(loyaltyDataInit(UID, brand));
    };

    const handleTabChange = (_, tab) => {
        dispatch(loyaltySetTab(tab));
    };

    const handlePageChange = (page, rowsPerPage) => {
        const getter = TAB_DATA_GETTERS[tab];
        if (getter) dispatch(getter(UID, brand, page, rowsPerPage));
    };

    const handleSummaryRetry = () => {
        dispatch(getLoyaltySummary(UID, brand));
    };

    const handleTabRetry = () => {
        const { page, rowsPerPage } = tabModel?.page;
        handlePageChange(page, rowsPerPage);
    };

    useEffect(() => {
        // Initialize tab data
        if (tab && tabModelNeedInit) {
            const getter = TAB_DATA_GETTERS[tab];
            if (getter) dispatch(getter(UID, brand));
        }
    }, [tab, tabModelNeedInit, dispatch, UID, brand]);

    return (
        <Box sx={{ py: 1 }}>
            <ActionBar model={model}>
                <ActionButton
                    disabled={!canAssign}
                    onClick={handleAssignPoints}
                >
                    {L.consumerpage_action_btn_assign_points}
                </ActionButton>
            </ActionBar>

            <Paper sx={{ py: 2, my: 1 }}>
                <Stack direction="row" alignItems="center">
                    <FormControl
                        sx={{
                            display: 'flex',
                            width: '16rem',
                            margin: '0 auto',
                            textAlign: 'center',
                        }}
                        size="small"
                    >
                        <InputLabel id="loyalty-brand-dropdown-label">
                            {L.consumerpage_loyalty_field_brand}
                        </InputLabel>
                        <Select
                            id="loyalty-brand-dropdown"
                            labelId="loyalty-brand-dropdown-label"
                            value={brand || ''}
                            label={L.consumerpage_loyalty_field_brand}
                            onChange={handleBrandChange}
                        >
                            {marketBrands.map((brand, index) => (
                                <MenuItem key={index} value={brand}>
                                    {brand}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <RefreshButton
                        disabled={!brand || summaryData?.loading}
                        onClick={handleSummaryRetry}
                    />
                </Stack>
            </Paper>

            {summaryData?.loading && <ProgressIndicator />}
            {summaryData?.error && (
                <SummaryError
                    error={summaryData?.error}
                    onRetry={handleSummaryRetry}
                />
            )}
            {summaryData?.data && (
                <FieldSection section={summarySection} model={summaryData} />
            )}

            {summaryData?.data && (
                <Paper>
                    <Stack direction="row" alignItems="center">
                        <Tabs
                            sx={{ flex: 1 }}
                            value={tab}
                            onChange={handleTabChange}
                        >
                            <Tab
                                label={L.consumerpage_loyalty_tab_transactions}
                                value="transaction"
                            />
                            <Tab
                                label={L.consumerpage_loyalty_tab_auditlogs}
                                value="auditlog"
                            />
                        </Tabs>
                        <RefreshButton
                            disabled={tabModel?.loading}
                            onClick={handleTabRetry}
                        />
                    </Stack>
                    {tabModel?.loading && <ProgressIndicator />}
                    {tabModel?.error && (
                        <ErrorIndicator
                            message={L.err_error_fetching_data}
                            retry={L.err_retry}
                            onRetry={handleTabRetry}
                        />
                    )}
                    {tabModel?.data && (
                        <TableSection
                            section={tabSection}
                            model={tabModel.data}
                            pagination={tabModel.page}
                            onPageChange={handlePageChange}
                        />
                    )}
                </Paper>
            )}
        </Box>
    );
};

export default TabLoyalty;
