import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { useMessages } from '../../../hooks/useMessages';
import ConsumerInfo from './ConsumerInfo';
import { FIELDS as F } from '../../../services/consumerFields';
import * as DM from '../../../services/dataModel';
import * as C from '../../../config/marketConfig';

function ActionBar({ model, children }) {
    const { L } = useMessages();
    const navigate = useNavigate();

    const { user } = useSelector((state) => state.auth);
    const { market } = user || {};

    const showLocked = useMemo(
        () => C.MV(C.MARKET_FIELDS.accountLocked, market).visible,
        [market]
    );

    const isActive = DM.getValue(model, F.IS_ACTIVE_EX);
    const isLocked = showLocked && DM.getValue(model, F.IS_LOCKED);
    const isDeleted = DM.getValue(model, F.IS_DELETED);

    const backgroundColor =
        isActive && !isLocked && !isDeleted
            ? 'tabHeader.main'
            : 'tabHeaderDisabled.main';

    const handleBack = () => {
        navigate('/search');
    };

    return (
        <Paper sx={{ mb: 1, backgroundColor }}>
            <Stack
                direction="row"
                alignItems="center"
                paddingLeft={1}
                paddingRight={2}
                paddingY={1.2}
                spacing={1}
            >
                <Tooltip title={L.consumerpage_action_nav_back}>
                    <IconButton onClick={handleBack}>
                        <ArrowBackIcon />
                    </IconButton>
                </Tooltip>
                <ConsumerInfo model={model} />
                {!isActive && !isDeleted && (
                    <Chip
                        size="small"
                        color="warning"
                        label={L.consumerpage_action_label_disabled}
                    />
                )}
                {isLocked && !isDeleted && (
                    <Chip
                        size="small"
                        color="warning"
                        label={L.consumerpage_action_label_locked}
                    />
                )}
                {isDeleted && (
                    <Chip
                        size="small"
                        color="error"
                        label={L.consumerpage_action_label_deleted}
                    />
                )}
                <Box sx={{ flexGrow: 1 }} />
                <Stack direction="row" spacing={1}>
                    {children}
                </Stack>
            </Stack>
        </Paper>
    );
}

export default ActionBar;
