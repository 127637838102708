// Authorization reducer

import * as types from '../actions/actionTypes';

const INITIAL_STATE = {
    loading: true,
    user: null,
    error: null,
};

export default function authReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.AUTH_SET_USER: {
            return {
                loading: false,
                user: action.user || null,
                error: action.error || null,
            };
        }

        default:
            return state;
    }
}
