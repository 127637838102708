// Notification actions

import * as types from './actionTypes';

export function notificationShow(message, severity) {
    return { type: types.NOTIFICATION_OPEN, message, severity };
}

export function notificationClose() {
    return { type: types.NOTIFICATION_CLOSE };
}

export function notifySuccess(message) {
    return notificationShow(message, 'success');
}

export function notifyError(message) {
    return notificationShow(message, 'error');
}
