// Authorization actions

import * as types from './actionTypes';
import * as authUtils from '../../services/authUtils';
import { notifyError } from './notificationActions';
import { getValue, setValue } from '../../services/storageUtils';

const KEY_MARKET = 'market';

export function authSetUser(user, error = null) {
    if (user) {
        if (user.market) {
            setValue(KEY_MARKET, user.market);
        } else {
            if (user.setMarket(getValue(KEY_MARKET))) {
                setValue(KEY_MARKET, user.market);
            }
        }
    }
    return { type: types.AUTH_SET_USER, user, error };
}

export function authReloadRequest() {
    return function (dispatch) {
        return Promise.resolve()
            .then(() => authUtils.getCurrentUser())
            .then((user) => dispatch(authSetUser(user)))
            .catch((error) => dispatch(authSetUser(null, error)));
    };
}

export function authNoSession(error) {
    return function (dispatch, getState) {
        const { auth } = getState();
        if (auth.user) {
            dispatch(authSetUser(null, error));
            dispatch(notifyError((L) => L.msg_logged_out));
        }
    };
}
