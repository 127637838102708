import React, { useCallback, useMemo, useState } from 'react';
import { Button, Menu, MenuItem, Typography } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useMessages } from '../../hooks/useMessages';

function MarketDropdown({ markets, market, onChange }) {
    const { L } = useMessages();
    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);
    const icon = open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />;

    const getLabel = useCallback(
        (market) => `${L.dict_country[market] || market}`,
        [L]
    );

    const options = useMemo(() => {
        return markets
            .sort()
            .map((market) => ({ value: market, label: getLabel(market) }));
    }, [markets, getLabel]);

    const hasOptions = options.length > 1;

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (market) => {
        setAnchorEl(null);
        onChange(market);
    };

    return !hasOptions ? (
        <Typography sx={{ pr: 1 }}>{getLabel(market)}</Typography>
    ) : (
        <>
            <Button
                onClick={handleOpen}
                endIcon={icon}
                sx={{
                    color: 'inherit',
                    textTransform: 'none',
                }}
            >
                <Typography>{getLabel(market)}</Typography>
            </Button>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem
                    disabled={true}
                    divider={true}
                    sx={{ fontStyle: 'italic' }}
                >
                    {L.navbar_market_choose_market}
                </MenuItem>
                {options.map((item) => (
                    <MenuItem
                        key={item.value}
                        onClick={() => handleClick(item.value)}
                    >
                        {item.label}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}

export default MarketDropdown;
