import React from 'react';
import Typography from '@mui/material/Typography';
import * as DM from '../../../services/dataModel';
import { FIELDS as F } from '../../../services/consumerFields';
import { formatAge } from '../../../services/formatUtils';
import { nvl } from '../../../services/typeUtils';

function ConsumerInfo({ model }) {
    const firstName = nvl(DM.getValue(model, F.FIRST_NAME));
    const lastName = nvl(DM.getValue(model, F.LAST_NAME));
    const dob = DM.getValue(model, F.DATE_OF_BIRTH);

    const age = formatAge(dob);
    const ageText = age ? `(${age})` : null;

    return (
        <>
            <Typography sx={{ fontSize: '1.1rem', fontWeight: 'bold' }}>
                {`${firstName} ${lastName}`}
            </Typography>
            <Typography>{ageText}</Typography>
        </>
    );
}

export default ConsumerInfo;
