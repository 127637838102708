// Auth Listener hook

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import * as authUtils from '../services/authUtils';
import * as authActions from '../store/actions/authActions';

export function useAuthListener() {
    const dispatch = useDispatch();

    useEffect(() => {
        function listener(ev) {
            const { event, data } = ev.payload || {};
            switch (event) {
                case 'signIn':
                case 'signUp':
                    const user = authUtils.getUserData(
                        data.signInUserSession.idToken.payload
                    );
                    dispatch(authActions.authSetUser(user));
                    break;
                case 'signOut':
                case 'oAuthSignOut':
                    dispatch(authActions.authSetUser(null));
                    break;
                case 'NO_USER_SESSION':
                case 'USER_SESSION_EXPIRED':
                    dispatch(authActions.authNoSession(event));
                    break;
                default:
                    return;
            }
        }

        authUtils.addAuthListener(listener);

        dispatch(authActions.authReloadRequest());

        return () => authUtils.removeAuthListener(listener);
    }, [dispatch]);
}
