import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
    executeConsumerGet,
    profileData,
    profileGetError,
} from '../../../store/actions/profileActions';
import { loyaltyReset } from '../../../store/actions/loyaltyActions';

import TabsBox from './TabsBox';
import { CircularProgress, Stack, Box } from '@mui/material';

import { dialogShow, dialogClose } from '../../../store/actions/dialogActions';

const ConsumerPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();

    const model = useSelector((state) => state.profile);
    const { data, errorGet } = model;
    const hasConsumer = !!data;

    useEffect(() => {
        dispatch(executeConsumerGet(id));
        return () => {
            dispatch(profileData(null));
            dispatch(loyaltyReset());
        };
    }, [dispatch, id]);

    useEffect(() => {
        if (errorGet !== null) {
            dispatch(
                dialogShow(
                    (L) => L.dialog_get_consumer_error_title,
                    (L) => L.dialog_get_consumer_error_text,
                    () => {
                        dispatch(profileGetError(null));
                        dispatch(executeConsumerGet(id));
                        dispatch(dialogClose());
                    },
                    () => {
                        dispatch(profileGetError(null));
                        navigate('/search');
                    }
                )
            );
        }
    }, [errorGet, id, dispatch, navigate]);

    return (
        <Stack alignItems="center">
            {!errorGet &&
                (hasConsumer ? (
                    <TabsBox model={model} />
                ) : (
                    <Box pt={16}>
                        <CircularProgress />
                    </Box>
                ))}
        </Stack>
    );
};

export default ConsumerPage;
