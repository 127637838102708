import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    Typography,
    CircularProgress,
    Stack,
} from '@mui/material';
import { useMessages } from '../../hooks/useMessages';
import { isFunction } from '../../services/typeUtils';
import { dialogClose } from '../../store/actions/dialogActions';

export function ConfirmDialogDefault() {
    const dispatch = useDispatch();
    const { L } = useMessages();

    const dialog = useSelector((state) => state.dialog);
    const { title, subTitle } = dialog;

    const titleText = isFunction(title) ? title(L) : title;
    const subtitleText = isFunction(subTitle) ? subTitle(L) : subTitle;

    const confirmText = dialog.options?.confirmText || L.dialog_btn_yes;
    const declineText = dialog.options?.declineText || L.dialog_btn_no;

    const CustomContent = dialog.options?.content;

    const onDialogClose = (event, reason) => {
        dispatch(dialogClose());
        if (dialog.onDecline) {
            dialog.onDecline(event, reason);
        }
    };

    return (
        <Dialog open={dialog.isOpen} onClose={onDialogClose}>
            {CustomContent && <CustomContent />}
            {!CustomContent && (
                <DialogContent
                    sx={{ mt: 1, minWidth: '360px', minHeight: '115px' }}
                >
                    <Typography variant="h6">{titleText}</Typography>
                    <Typography variant="subtitle2">{subtitleText}</Typography>
                </DialogContent>
            )}
            <DialogActions sx={{ mb: 1, mx: 2 }}>
                {dialog.isLoading ? (
                    <Stack sx={{ width: '100%', alignItems: 'center' }}>
                        <CircularProgress />
                    </Stack>
                ) : (
                    <>
                        <Button
                            variant="outlined"
                            onClick={onDialogClose}
                            sx={{ minWidth: '5.6rem' }}
                        >
                            {declineText}
                        </Button>
                        <Button
                            variant="contained"
                            color="error"
                            onClick={dialog.onConfirm}
                            sx={{ minWidth: '5.6rem' }}
                        >
                            {confirmText}
                        </Button>
                    </>
                )}
            </DialogActions>
        </Dialog>
    );
}

export function ConfirmDialog() {
    const { component } = useSelector((state) => state.dialog);
    const DialogComponent = component || ConfirmDialogDefault;
    return <DialogComponent />;
}

export default ConfirmDialog;
