// Root reducer

import { combineReducers } from 'redux';

import authReducer from './authReducer';
import localeReducer from './localeReducer';
import searchReducer from './searchReducer';
import profileReducer from './profileReducer';
import notificationReducer from './notificationReducer';
import dialogReducer from './dialogReducer';
import brandReducer from './brandReducer';
import loyaltyReducer from './loyaltyReducer';

const rootReducer = combineReducers({
    auth: authReducer,
    locale: localeReducer,
    search: searchReducer,
    profile: profileReducer,
    notification: notificationReducer,
    dialog: dialogReducer,
    brand: brandReducer,
    loyalty: loyaltyReducer,
});

export default rootReducer;
