import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMessages, languages, labels } from '../l10n';
import { isFunction } from '../services/typeUtils';
import * as actions from '../store/actions/localeActions';

const DEFAULT_LANG = 'en';

export function useMessages() {
    const dispatch = useDispatch();
    const { lang } = useSelector((store) => store.locale);

    return useMemo(() => {
        const setLanguage = (lang) => dispatch(actions.setLanguage(lang));

        if (!lang) {
            dispatch(actions.initLanguage());
        }

        const language = lang || DEFAULT_LANG;
        const L = getMessages(language);

        const getMessage = (message) =>
            isFunction(message) ? message(L) : message;

        return { L, language, languages, labels, setLanguage, getMessage };
    }, [dispatch, lang]);
}
