import { API } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
import { getIdToken } from './authUtils';
import { filterUnique, flatten, normalizeArray } from './objectUtils';
import {
    nonEmptyString,
    normalizeString,
    toLowerCase,
    toUpperCase,
} from './typeUtils';
import { formatBool, validateDate } from './formatUtils';
import { ApiError } from './errors';

const API_NAME = 'admin-portal-api';
const CATEGORY = 'CC';

const getConfig = async (body = undefined) => {
    return {
        headers: {
            Authorization: await getIdToken(),
            'X-Correlation-Id': uuidv4(),
        },
        body,
    };
};

export const validateResponse = (resp) => {
    // console.log('CONSUMER_API response:', resp);
    return resp;
};

const handleError = (err) => {
    // console.error('CONSUMER_API error:', err);
    throw new ApiError(err);
};

export async function consumerSearch(country, params) {
    const path = `/v1/${country}/consumerSearch`;
    return getConfig(params)
        .then((config) => API.post(API_NAME, path, config))
        .then(validateResponse)
        .catch(handleError);
}

export async function consumerGet(country, consumerId) {
    const path = `/v1/${country}/consumer/${consumerId}`;
    return getConfig()
        .then((config) => API.get(API_NAME, path, config))
        .then(validateResponse)
        .catch(handleError);
}

export async function consumerUpdate(country, consumerId, params) {
    const path = `/v1/${country}/consumer/${consumerId}`;
    return getConfig(params)
        .then((config) => API.patch(API_NAME, path, config))
        .then(validateResponse)
        .catch(handleError);
}

export async function consumerDelete(country, consumerId) {
    const path = `/v1/${country}/consumer/${consumerId}`;
    return getConfig()
        .then((config) => API.del(API_NAME, path, config))
        .then(validateResponse)
        .catch(handleError);
}

export async function consumerActivation(country, consumerId, params) {
    const path = `/v1/${country}/activation/${consumerId}`;
    return getConfig(params)
        .then((config) => API.patch(API_NAME, path, config))
        .then(validateResponse)
        .catch(handleError);
}

export async function consumerBlock(country, consumerId, reason) {
    const params = { isActive: false, reason };
    return consumerActivation(country, consumerId, params);
}

export async function consumerUnblock(country, consumerId) {
    const params = { isActive: true };
    return consumerActivation(country, consumerId, params);
}

export async function resetPassword(country, params) {
    const path = `/v1/${country}/reset-password`;
    return getConfig(params)
        .then((config) => API.post(API_NAME, path, config))
        .then(validateResponse)
        .catch(handleError);
}

export async function avSet(country, consumerId) {
    const path = `/v1/${country}/av-set/${consumerId}`;
    return getConfig()
        .then((config) => API.get(API_NAME, path, config))
        .then(validateResponse)
        .catch(handleError);
}

export async function getBrandData(country) {
    const path = `/v1/${country}/dictionary?field=consumer.primaryBrand`;
    return getConfig()
        .then((config) => API.get(API_NAME, path, config))
        .then(validateResponse)
        .then(normalizeBrandData)
        .catch(handleError);
}

export async function loyaltySummary(country, brand, consumerId) {
    const qs = new URLSearchParams({ category: CATEGORY, brand });
    const path = `/v1/${country}/loyalty/${consumerId}/summary?${qs}`;
    return getConfig()
        .then((config) => API.get(API_NAME, path, config))
        .then(validateResponse)
        .catch(handleError);
}

export async function loyaltyTransactions(
    country,
    brand,
    consumerId,
    page = 0,
    rowsPerPage = 10
) {
    const qs = new URLSearchParams({
        category: CATEGORY,
        brand,
        start: page * rowsPerPage,
        limit: rowsPerPage,
    });
    const path = `/v1/${country}/loyalty/${consumerId}/transactions?${qs}`;
    return getConfig()
        .then((config) => API.get(API_NAME, path, config))
        .then(validateResponse)
        .then((result) => ({
            count: result?.data?.totalCount || 0,
            data: result?.data?.results || [],
            page,
            rowsPerPage,
        }))
        .catch(handleError);
}

export async function loyaltyPoints(
    country,
    brand,
    consumerId,
    points,
    remark
) {
    const qs = new URLSearchParams({ category: CATEGORY, brand });
    const path = `/v1/${country}/loyalty/${consumerId}/points?${qs}`;
    const body = { points, remark };
    return getConfig(body)
        .then((config) => API.post(API_NAME, path, config))
        .then(validateResponse)
        .catch(handleError);
}

export async function loyaltyLogs(
    country,
    brand,
    consumerId,
    page = 0,
    rowsPerPage = 10
) {
    const qs = new URLSearchParams({
        category: CATEGORY,
        brand,
        start: page * rowsPerPage,
        limit: rowsPerPage,
    });
    const path = `/v1/${country}/loyalty/${consumerId}/logs?${qs}`;
    return getConfig()
        .then((config) => API.get(API_NAME, path, config))
        .then(validateResponse)
        .then((result) => ({
            count: result?.data?.totalCount || 0,
            data: result?.data?.results || [],
            page,
            rowsPerPage,
        }))
        .catch(handleError);
}

export async function loyaltyRecalcTier(country, brand, consumerId) {
    const qs = new URLSearchParams({ category: CATEGORY, brand });
    const path = `/v1/${country}/loyalty/${consumerId}/recalc?${qs}`;
    return getConfig()
        .then((config) => API.get(API_NAME, path, config))
        .then(validateResponse)
        .catch(handleError);
}

export function normalizeConsumerGet(data, country) {
    if (data != null) {
        // Normalize Profile
        data.profile = data.profile || {};
        data.profile2 = {};
        const { profile, profile2 } = data;

        // Normalize Address
        const defaultAddress = { type: 'personal' };
        profile.address = normalizeArray(profile.address);
        profile.address2 = [defaultAddress, ...profile.address];
        profile.address2 = profile.address2.reduce((result, item) => {
            result[String(item.type).toLowerCase()] = item;
            return result;
        }, {});

        // Normalize Phones
        profile.phones = normalizeArray(profile.phones);
        profile.phones2 = profile.phones.reduce((result, item) => {
            result[String(item.type).toLowerCase()] = item.number;
            return result;
        }, {});

        // Normalize profile flags
        profile2.isActive = !(profile.isActive === false);
        profile2.isLocked =
            validateDate(data.lockedUntil) &&
            new Date(data.lockedUntil) > new Date();

        // Normalize Profile verification status
        profile2.verificationStatus =
            profile.profileVerified === true ? 'VERIFIED' : 'NOT VERIFIED';
        profile2.verificationDate = null;

        // Normalize Email verification status
        const email = toLowerCase(normalizeString(profile.email));
        profile2.emailVerified = nonEmptyString(email)
            ? normalizeArray(profile.emails?.verified)
                  .map(normalizeString)
                  .map(toLowerCase)
                  .includes(email)
            : null;
        profile2.emailVerificationStatus = formatBool(
            profile2.emailVerified,
            'VERIFIED',
            'NOT VERIFIED'
        );

        // Normalize mobile verification status
        const mobilePresent = nonEmptyString(profile.phones2.mobile);
        profile2.mobileVerified = mobilePresent
            ? profile.phoneNumber != null
            : null;
        profile2.mobileVerificationStatus = formatBool(
            profile2.mobileVerified,
            'VERIFIED',
            'NOT VERIFIED'
        );

        // Normalize Consents
        data.consents2 = {};
        data.consents2.brands = normalizeArray(
            data?.consents?.brandPreferences?.entitlements
        ).reduce((result, item) => {
            result[String(item).toLowerCase()] = true;
            return result;
        }, {});

        // Normalize isDeleted flag
        data.consents2.isDeleted = data.consents?.databaseOptIn === false;

        // Normalize HAV
        data.hardAgeVerification = normalizeArray(data.hardAgeVerification);
        data.hav = data.hardAgeVerification.find(
            (item) =>
                item.hardAVCountryRefCode === country &&
                item.hardAVMarketRefCode === `${country}04`
        );

        // Normalize Mobile status
        if (data.mobileVerification?.status) {
            data.mobileVerification.status = toUpperCase(
                data.mobileVerification.status
            );
        }

        // Normalize Blockings
        const blockingValues = Object.values(data.blockings || {});
        const hasBlockingData = blockingValues.some((value) => !!value);
        const isBlockingActive = hasBlockingData && !profile2.isActive;
        const isBlockingHistorical = hasBlockingData && !isBlockingActive;
        data.blockings2 = {
            hasBlockingData,
            isBlockingActive,
            isBlockingHistorical,
        };

        // Flatten the result
        data = flatten(data);
    }

    return data;
}

export function normalizeConsumerSearch(data) {
    if (data) {
        data = normalizeArray(data);
        data.forEach(normalizeConsumerSearchItem);
    }
    return data;
}

export function normalizeConsumerSearchItem(item) {
    if (item) {
        item.profile = item.profile || {};
        item.profile.address = normalizeArray(item.profile.address);
        item.profile.phones = normalizeArray(item.profile.phones);

        // Normalize isActive
        item.profile2 = {};
        item.profile2.isActive = !(item.profile.isActive === false);

        // Normalize Address
        const addrItems = [
            'addressLine2',
            'addressLine1',
            'postal',
            'city',
            'province',
        ];
        const address =
            item.profile.address.find((item) => item.type === 'personal') ||
            item.profile.address[0];
        const addr = address || {};
        item.profile.address2 = addrItems
            .map((item) => addr[item])
            .filter((item) => !!item && String(item).trim().length)
            .join(', ');

        // Normalize Phone
        const phone =
            item.profile.phones.find((item) => item.type === 'mobile') ||
            item.profile.phones[0];
        const { number } = phone || {};
        item.profile.phone = number;
    }
    return item;
}

function normalizeBrandData(payload) {
    const { data } = payload || {};

    const compareByMarket = (a, b) => a.market.localeCompare(b.market);

    const filterNonEmpty = (item) =>
        nonEmptyString(item.id) &&
        nonEmptyString(item.desc) &&
        nonEmptyString(item.market);

    const createDescMap = (result, item) => {
        result[item.desc] = item;
        return result;
    };

    const brands = normalizeArray(data)
        .map((item) => {
            const brand = item.brandFamily;

            const subBrands = normalizeArray(brand.brandSubFamily)
                .map((item2) => ({
                    id: normalizeString(item2.brandSubFamilyID),
                    desc: normalizeString(item2.brandSubFamilyDesc),
                    market: normalizeString(item2.brandSubFamilyDescMarket),
                }))
                .filter(filterNonEmpty)
                .filter(filterUnique((item) => item.desc))
                .sort(compareByMarket);

            const subBrandsMap = subBrands.reduce(createDescMap, {});

            return {
                id: normalizeString(brand.brandFamilyID),
                desc: normalizeString(brand.brandFamilyDesc),
                market: normalizeString(brand.brandFamilyDescMarket),
                subBrands,
                subBrandsMap,
            };
        })
        .filter(filterNonEmpty)
        .filter(filterUnique((item) => item.desc))
        .sort(compareByMarket);

    const brandsMap = brands.reduce(createDescMap, {});

    return { brands, brandsMap };
}
