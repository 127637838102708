// Action types

export const AUTH_SET_USER = 'AUTH_SET_USER';
export const LOCALE_SET_LANG = 'LOCALE_SET_LANG';

export const SEARCH_CHANGE = 'SEARCH_CHANGE';
export const SEARCH_START = 'SEARCH_START';
export const SEARCH_RESULT = 'SEARCH_RESULT';
export const SEARCH_RESULT_UPDATE = 'SEARCH_RESULT_UPDATE';
export const SEARCH_ERROR = 'SEARCH_ERROR';
export const SEARCH_CLEAR = 'SEARCH_CLEAR';
export const SEARCH_SET_PAGE = 'SEARCH_SET_PAGE';
export const SEARCH_SET_ROWS_PER_PAGE = 'SEARCH_SET_ROWS_PER_PAGE';
export const SEARCH_SET_ORDER = 'SEARCH_SET_ORDER';
export const SEARCH_SET_ORDER_BY = 'SEARCH_SET_ORDER_BY';

export const PROFILE_DATA = 'PROFILE_DATA';
export const PROFILE_EDIT_MODE = 'PROFILE_EDIT_MODE';
export const PROFILE_VALIDATE_REQUIRED = 'PROFILE_VALIDATE_REQUIRED';
export const PROFILE_CHANGE = 'PROFILE_CHANGE';
export const PROFILE_CHANGE_CLEAR = 'PROFILE_CHANGE_CLEAR';
export const PROFILE_GET_ERROR = 'PROFILE_GET_ERROR';
export const PROFILE_UPD_ERROR = 'PROFILE_UPD_ERROR';

export const NOTIFICATION_OPEN = 'NOTIFICATION_OPEN';
export const NOTIFICATION_CLOSE = 'NOTIFICATION_CLOSE';

export const DIALOG_OPEN = 'DIALOG_OPEN';
export const DIALOG_CLOSE = 'DIALOG_CLOSE';
export const DIALOG_LOADING = 'DIALOG_LOADING';
export const DIALOG_ERROR = 'DIALOG_ERROR';

export const BRAND_SET_DATA = 'BRAND_SET_DATA';
export const BRAND_SET_LOADING = 'BRAND_SET_LOADING';
export const BRAND_RESET = 'BRAND_RESET';

export const LOYALTY_RESET = 'LOYALTY_RESET';
export const LOYALTY_SET_BRAND = 'LOYALTY_SET_BRAND';
export const LOYALTY_SET_TAB = 'LOYALTY_SET_TAB';
export const LOYALTY_SET_SUMMARY_LOADING = 'LOYALTY_SET_SUMMARY_LOADING';
export const LOYALTY_SET_SUMMARY_DATA = 'LOYALTY_SET_SUMMARY_DATA';
export const LOYALTY_SET_SUMMARY_ERROR = 'LOYALTY_SET_SUMMARY_ERROR';
export const LOYALTY_SET_TRANS_LOADING = 'LOYALTY_SET_TRANS_LOADING';
export const LOYALTY_SET_TRANS_DATA = 'LOYALTY_SET_TRANS_DATA';
export const LOYALTY_SET_TRANS_ERROR = 'LOYALTY_SET_TRANS_ERROR';
export const LOYALTY_SET_AUDIT_LOADING = 'LOYALTY_SET_AUDIT_LOADING';
export const LOYALTY_SET_AUDIT_DATA = 'LOYALTY_SET_AUDIT_DATA';
export const LOYALTY_SET_AUDIT_ERROR = 'LOYALTY_SET_AUDIT_ERROR';
export const LOYALTY_SET_RECALC_LOADING = 'LOYALTY_SET_RECALC_LOADING';
export const LOYALTY_SET_RECALC_DATA = 'LOYALTY_SET_RECALC_DATA';
export const LOYALTY_SET_ERROR = 'LOYALTY_SET_ERROR';
