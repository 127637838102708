// Data item utilities

import {
    formatBool,
    formatDateEx,
    formatDateTimeEx,
    normalizeDateFormatEx,
} from './formatUtils';
import { asString } from './typeUtils';

const formatDefault = (value) => value;
const normalizeDefault = (value) => value;
const getLabelsDefault = () => [];

export class Item {
    constructor(label, field) {
        this._type = 'text';
        this._label = label;
        this._field = field;
        this._visible = true;
        this._autofocus = false;
        this._editable = false;
        this._showChanges = true;
        this._required = false;
        this._requiredMsg = null;
        this._wrap = false;
        this._validators = [];
        this._hinters = [];
        this._getLabels = getLabelsDefault;
        this._format = formatDefault;
        this._normalize = normalizeDefault;
        this._reformat = (value) => this._format(this._normalize(value));
        this._values = null;
        this._valuesMap = null;
        this._validator = (value) => {
            for (const checker of this._validators) {
                const message = checker(value, this._normalize(value));
                if (message) return message;
            }
        };
        this._hinter = (value) => {
            for (const checker of this._hinters) {
                const message = checker(value, this._normalize(value));
                if (message) return message;
            }
        };
    }

    static new(label, field) {
        return new Item(label, field);
    }

    labelIf(flag, label) {
        if (flag) {
            this._label = label;
        }
        return this;
    }

    visible() {
        return this.visibleIf(true);
    }

    visibleIf(flag) {
        this._visible = !!flag;
        return this;
    }

    autofocus() {
        this._autofocus = true;
        return this;
    }

    editable() {
        return this.editableIf(true);
    }

    editableIf(flag) {
        this._editable = !!flag;
        return this;
    }

    showChanges(flag) {
        this._showChanges = !!flag;
        return this;
    }

    required(message) {
        return this.requiredIf(true, message);
    }

    requiredIf(flag, message) {
        this._required = !!flag;
        this._requiredMsg = message;
        return this;
    }

    wrap(flag) {
        this._wrap = !!flag;
        return this;
    }

    labelsProvider(provider) {
        this._getLabels = provider;
        return this;
    }

    validator(validator) {
        this._validators.push(validator);
        return this;
    }

    hinter(hinter) {
        this._hinters.push(hinter);
        return this;
    }

    normalizeValidator(message) {
        return this.validator(
            (value) => this._normalize(value) == null && message
        );
    }

    dropdownValidator(message) {
        return this.validator(
            (value) => this._valuesMap?.[value] == null && message
        );
    }

    dateValidator(message, mask) {
        return this.validator(
            (value) => normalizeDateFormatEx(value, mask) == null && message
        );
    }

    numberValidator(message, min, max) {
        return this.validator(
            (value) => !(+value >= min && +value <= max) && message
        );
    }

    regexpValidator(regexp, message) {
        const validate = (reg, val, msg) => reg && !reg.test(val) && msg;

        const simpleValidator = (value) => {
            return validate(regexp, value, message);
        };

        const arrayValidator = (value) => {
            for (const [sel, reg] of regexp) {
                if (sel && sel.test(value)) {
                    return validate(reg, value, message);
                }
            }
            return message;
        };

        return Array.isArray(regexp)
            ? this.validator(arrayValidator)
            : this.validator(simpleValidator);
    }

    maxLength(maxLen, message) {
        return this.validator(
            (value) => asString(value).length > maxLen && message
        );
    }

    format(format) {
        this._format = format;
        return this;
    }

    normalize(normalize) {
        this._normalize = normalize;
        return this;
    }

    bool(yes, no) {
        return this.format((value) => formatBool(value, yes, no));
    }

    date({ fmt, mask }) {
        this._type = 'date';
        this.format((value) => formatDateEx(fmt, value));
        this.normalize((value) => normalizeDateFormatEx(value, mask));
        return this;
    }

    datetime(fmt) {
        return this.format((value) => formatDateTimeEx(fmt, value));
    }

    checkbox() {
        this._type = 'checkbox';
        return this;
    }

    dropdown(labels, editValues = null) {
        const getLabel = (value) => labels[value] || value;
        const createMap = (values) =>
            values.reduce((result, { value, label }) => {
                result[value] = label;
                return result;
            }, {});
        const allValues = Object.keys(labels);
        const filteredValues = Array.isArray(editValues)
            ? allValues.filter((value) => editValues.includes(value))
            : allValues;
        this._type = 'dropdown';
        this._format = getLabel;
        this._values = filteredValues.map((value) => ({
            value,
            label: getLabel(value),
        }));
        this._valuesMap = createMap(this._values);
        return this;
    }

    build() {
        return {
            type: this._type,
            label: this._label,
            field: this._field,
            visible: this._visible,
            autofocus: this._autofocus,
            editable: this._editable,
            showChanges: this._showChanges,
            required: this._required,
            requiredMsg: this._requiredMsg,
            wrap: this._wrap,
            getLabels: this._getLabels,
            validator: this._validator,
            hinter: this._hinter,
            format: this._format,
            normalize: this._normalize,
            reformat: this._reformat,
            values: this._values,
            valuesMap: this._valuesMap,
            isText: this._type === 'text',
            isDate: this._type === 'date',
            isCheckbox: this._type === 'checkbox',
            isDropdown: this._type === 'dropdown',
        };
    }
}
