// Brand Segmentation fields

import { Item as I } from '../../../../services/itemUtils';
import { FIELDS as F } from '../../../../services/consumerFields';
import * as C from '../../../../config/marketConfig';

export function getFields(L, market) {
    const sectionLabel = C.MV(C.MARKET_LABELS.brandSegm, market, L);

    const config = C.MV(C.MARKET_CONFIG_BRANDS, market);
    const { brand = {}, subBrand = {} } = config || {};

    const itemBrand = I.new(
        L.consumerpage_marketing_field_brand,
        F.BRAND_FAMILY
    )
        .visibleIf(!!config.brand)
        .editableIf(brand.editable)
        .requiredIf(brand.required, L.consumerpage_error_required)
        .build();

    const itemBrandId = I.new('Brand Id', F.BRAND_FAMILY_ID)
        .visibleIf(false)
        .editableIf(brand.editable)
        .requiredIf(brand.required, L.consumerpage_error_required)
        .build();

    const itemSubBrand = I.new(
        L.consumerpage_marketing_field_subbrand,
        F.BRAND_SUB_FAMILY
    )
        .visibleIf(!!config.subBrand)
        .editableIf(subBrand.editable)
        .requiredIf(subBrand.required, L.consumerpage_error_required)
        .build();

    const itemSubBrandId = I.new('Sub Brand Id', F.BRAND_SUB_FAMILY_ID)
        .visibleIf(false)
        .editableIf(subBrand.editable)
        .requiredIf(subBrand.required, L.consumerpage_error_required)
        .build();

    return {
        sectionLabel,
        itemBrand,
        itemBrandId,
        itemSubBrand,
        itemSubBrandId,
    };
}
