import { CircularProgress, Stack } from '@mui/material';

function ProgressIndicator({ alignment = 'center' }) {
    return (
        <Stack sx={{ alignItems: alignment, padding: 4 }}>
            <CircularProgress />
        </Stack>
    );
}

export default ProgressIndicator;
