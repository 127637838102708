export class ItemValue {
    constructor(item, value) {
        this.item = item;
        this.value = value;
    }

    get normalized() {
        return this.item.normalize(this.value);
    }

    toString() {
        return this.value;
    }
}
