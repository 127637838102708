// Dialog actions

import * as types from './actionTypes';

export function dialogShow(
    title,
    subTitle,
    onConfirm,
    onDecline = null,
    options = null
) {
    return {
        type: types.DIALOG_OPEN,
        title,
        subTitle,
        onConfirm,
        onDecline,
        options,
    };
}

export function dialogShowCustom(
    component,
    onConfirm = null,
    onDecline = null
) {
    return {
        type: types.DIALOG_OPEN,
        component,
        onConfirm,
        onDecline,
    };
}

export function dialogClose() {
    return { type: types.DIALOG_CLOSE };
}

export function dialogLoading(isLoading = true) {
    return { type: types.DIALOG_LOADING, isLoading };
}

export function dialogError(error) {
    return { type: types.DIALOG_ERROR, error };
}
