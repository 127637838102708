// Consumer Profile reducer

import { FIELDS as F } from '../../services/consumerFields';
import * as DM from '../../services/dataModel';
import * as types from '../actions/actionTypes';

const INITIAL_STATE = {
    data: null,
    editMode: false,
    changes: null,
    errors: null,
    hints: null,
    errorGet: null,
    errorUpd: null,
};

const MODEL_FIELDS = {
    '/profile/firstName': F.FIRST_NAME,
    '/profile/lastName': F.LAST_NAME,
    '/profile/dateOfBirth': F.DATE_OF_BIRTH,
    '/profile/gender': F.GENDER,
    '/profile/email': F.EMAIL,
    '/profile/languages': F.LANGUAGES,
    '/profile/address/0/country': F.ADDR_PERSONAL_COUNTRY,
    '/profile/address/0/province': F.ADDR_PERSONAL_PROVINCE,
    '/profile/address/0/city': F.ADDR_PERSONAL_CITY,
    '/profile/address/0/addressLine1': F.ADDR_PERSONAL_LINE1,
    '/profile/address/0/addressLine2': F.ADDR_PERSONAL_LINE2,
    '/profile/address/0/postal': F.ADDR_PERSONAL_POSTAL,
};

export default function profileReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.PROFILE_DATA: {
            return {
                ...INITIAL_STATE,
                data: action.data,
            };
        }

        case types.PROFILE_EDIT_MODE: {
            const editMode =
                action.editMode != null ? action.editMode : !state.editMode;
            return {
                ...state,
                editMode,
                changes: null,
                errors: null,
                hints: null,
            };
        }

        case types.PROFILE_VALIDATE_REQUIRED: {
            const { sections } = action;

            Array.isArray(sections) &&
                sections.forEach(
                    (section) =>
                        Array.isArray(section?.items) &&
                        section.items
                            .filter((item) => item?.editable)
                            .forEach(
                                (item) =>
                                    (state = DM.checkFieldRequired(state, item))
                            )
                );

            return state;
        }

        case types.PROFILE_CHANGE: {
            const { itemValue } = action;
            return DM.setItemValue(state, itemValue);
        }

        case types.PROFILE_CHANGE_CLEAR: {
            return { ...state, changes: null, errors: null, hints: null };
        }

        case types.PROFILE_GET_ERROR: {
            return {
                ...INITIAL_STATE,
                errorGet: action.error,
                errorUpd: null,
            };
        }

        case types.PROFILE_UPD_ERROR: {
            const error = action.error;

            if (error?.isValidationError) {
                const field = MODEL_FIELDS[error?.details?.field];
                if (field) {
                    state = DM.setFieldError(
                        state,
                        field,
                        (L) => L.consumerpage_error_rejected_by_server
                    );
                }
            }

            return {
                ...state,
                errorGet: null,
                errorUpd: error,
            };
        }

        default:
            return state;
    }
}
