// Email helper

import { asString, capitalizeFirstLetter, toLowerCase } from './typeUtils';

const REGEX_EMAIL_SEP = /@/;
const REGEX_USER_SEP = /[\._]/;
const REGEX_DOMAIN_SEP = /\./;

export class Email {
    constructor(email) {
        this.email = toLowerCase(asString(email));

        const [user, domain] = this.email.split(REGEX_EMAIL_SEP);
        this.user = asString(user);
        this.domain = asString(domain);

        this.userParts = this.user
            .split(REGEX_USER_SEP)
            .filter((item) => item.length);
        this.domainParts = this.domain
            .split(REGEX_DOMAIN_SEP)
            .filter((item) => item.length);

        this.userPretty = this.userParts.map(capitalizeFirstLetter).join(' ');
    }
}
