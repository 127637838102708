// Age Verification tab fields

import { Item as I } from '../../../../services/itemUtils';
import { FIELDS as F } from '../../../../services/consumerFields';
import * as C from '../../../../config/marketConfig';

export function getFields(L, market) {
    const items1 = [
        I.new(L.consumerpage_age_field_dob, F.DATE_OF_BIRTH)
            .date(C.getMarketValue(C.MARKET_FORMAT_DATE, market))
            .build(),
        I.new(L.consumerpage_age_field_home_country, F.COUNTRY_CODE)
            .dropdown(L.dict_country)
            .build(),
    ];

    const items2 = [
        I.new(L.consumerpage_age_field_hav_last_updated, F.HAV_LAST_UPD_DATE)
            .datetime(C.getMarketValue(C.MARKET_FORMAT_DATETIME, market))
            .build(),
        I.new(L.consumerpage_age_field_hav_country, F.HAV_COUNTRY_CODE)
            .dropdown(L.dict_country)
            .build(),
        I.new(L.consumerpage_age_field_hav_type, F.HAV_TYPE).build(),
        I.new(L.consumerpage_age_field_hav_status, F.HAV_STATUS)
            .dropdown(L.dict_hav_status)
            .build(),
    ];

    return [
        { id: 1, items: items1 },
        { id: 2, items: items2, label: L.consumerpage_age_section_label_hav },
    ];
}
