// Loyalty reducer

import * as types from '../actions/actionTypes';

const INITIAL_PAGE = {
    page: 0,
    rowsPerPage: 10,
};

const INITIAL_STATE = {
    brand: null,
    tab: 'transaction',
    summaryData: null,
    transactionData: { data: null, page: INITIAL_PAGE },
    auditData: { data: null, page: INITIAL_PAGE },
    recalcData: null,
    error: null,
};

export default function loyaltyReducer(state = INITIAL_STATE, action) {
    const { type, payload, error } = action;

    switch (type) {
        case types.LOYALTY_RESET: {
            return { ...INITIAL_STATE };
        }

        case types.LOYALTY_SET_BRAND: {
            return { ...INITIAL_STATE, brand: action.brand };
        }

        case types.LOYALTY_SET_TAB: {
            return { ...state, tab: action.tab };
        }

        case types.LOYALTY_SET_SUMMARY_LOADING: {
            return {
                ...state,
                summaryData: { loading: true },
                transactionData: { data: null, page: INITIAL_PAGE }, // will trigger transactions refresh
                auditData: { data: null, page: INITIAL_PAGE }, // will trigger auditlog refresh
                error: null,
            };
        }

        case types.LOYALTY_SET_SUMMARY_DATA: {
            return {
                ...state,
                summaryData: { data: payload },
                error: null,
            };
        }

        case types.LOYALTY_SET_SUMMARY_ERROR: {
            return {
                ...state,
                summaryData: { error },
                error,
            };
        }

        case types.LOYALTY_SET_TRANS_LOADING: {
            const { page, rowsPerPage } = payload;
            return {
                ...state,
                transactionData: {
                    data: null,
                    page: { page, rowsPerPage },
                    loading: true,
                },
                error: null,
            };
        }

        case types.LOYALTY_SET_TRANS_DATA: {
            const { count, data, page, rowsPerPage } = payload;
            return {
                ...state,
                transactionData: {
                    data: { count, data },
                    page: { page, rowsPerPage },
                },
                error: null,
            };
        }

        case types.LOYALTY_SET_TRANS_ERROR: {
            return {
                ...state,
                transactionData: {
                    data: null,
                    page: state.transactionData.page,
                    error,
                },
                error,
            };
        }

        case types.LOYALTY_SET_AUDIT_LOADING: {
            const { page, rowsPerPage } = payload;
            return {
                ...state,
                auditData: {
                    data: null,
                    page: { page, rowsPerPage },
                    loading: true,
                },
                error: null,
            };
        }

        case types.LOYALTY_SET_AUDIT_DATA: {
            const { count, data, page, rowsPerPage } = payload;
            return {
                ...state,
                auditData: {
                    data: { count, data },
                    page: { page, rowsPerPage },
                },
                error: null,
            };
        }

        case types.LOYALTY_SET_AUDIT_ERROR: {
            return {
                ...state,
                auditData: {
                    data: null,
                    page: state.auditData.page,
                    error,
                },
                error,
            };
        }

        case types.LOYALTY_SET_ERROR: {
            return { ...state, error: action.error };
        }

        case types.LOYALTY_SET_RECALC_LOADING: {
            return {
                ...state,
                recalcData: { loading: true },
                error: null,
            };
        }

        case types.LOYALTY_SET_RECALC_DATA: {
            return {
                ...state,
                recalcData: { requested: payload },
                error: null,
            };
        }

        default:
            return state;
    }
}
