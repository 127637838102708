import { Button, Stack, Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ReplayIcon from '@mui/icons-material/Replay';

const COLORS = {
    DEFAULT: 'error',
    error: 'error',
    info: 'info',
};

const ICONS = {
    DEFAULT: ErrorOutlineIcon,
    error: ErrorOutlineIcon,
    info: InfoOutlinedIcon,
};

function ErrorIndicator({ type, message, retry, onRetry }) {
    const color = COLORS[type] || COLORS.DEFAULT;
    const Icon = ICONS[type] || ICONS.DEFAULT;

    return (
        <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            padding={4}
            spacing={2}
        >
            <Icon color={color} />
            <Typography color={color}>{message}</Typography>
            {retry && onRetry && (
                <Button
                    variant="text"
                    color="primary"
                    onClick={onRetry}
                    endIcon={<ReplayIcon />}
                >
                    {retry}
                </Button>
            )}
        </Stack>
    );
}

export default ErrorIndicator;
