import React, { useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import AppBar from '@mui/material/AppBar';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';

import { useMessages } from '../../hooks/useMessages';
import { signOut } from '../../services/authUtils';
import LanguageDropdown from './LanguageDropdown';
import MarketDropdown from './MarketDropdown';
import { authSetUser } from '../../store/actions/authActions';
import { searchClear } from '../../store/actions/searchActions';
import { brandReset } from '../../store/actions/brandActions';
import { notifySuccess } from '../../store/actions/notificationActions';

const ENV = process.env.REACT_APP_ENV;

const envBadge = ['LOCAL', 'DEV', 'QA', 'PRD'].includes(ENV) ? ENV : '';

const styleLink = {
    color: 'inherit',
    textDecoration: 'none',
};

const autohide = {
    display: { xs: 'none', sm: 'block' },
};

const MenuHeader = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { L } = useMessages();

    const { user } = useSelector((state) => state.auth);
    const { hasAccess, markets = [], market = null } = user || {};

    const username = user?.email?.userPretty;

    const handleMarketChange = useCallback(
        (market) => {
            if (user?.setMarket(market)) {
                dispatch(authSetUser(user));
                dispatch(searchClear());
                dispatch(brandReset());
                dispatch(notifySuccess(L.msg_market_changed));
                navigate('/');
            }
        },
        [dispatch, navigate, L, user]
    );

    const handleLogout = useCallback(() => signOut(), []);

    return (
        <>
            <AppBar>
                <Toolbar>
                    <Stack
                        direction="row"
                        alignItems="center"
                        spacing={2}
                        flexGrow={1}
                    >
                        <Badge
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            color="warning"
                            badgeContent={envBadge}
                            invisible={!envBadge}
                        >
                            <Typography variant="h6" mr={1}>
                                <Link style={styleLink} to="/">
                                    {L.navbar_admin_portal}
                                </Link>
                            </Typography>
                        </Badge>
                        {hasAccess && (
                            <Typography>
                                <Link style={styleLink} to="/search">
                                    {L.navbar_consumer_search}
                                </Link>
                            </Typography>
                        )}
                        <Box flexGrow={1} />
                        <LanguageDropdown />
                        {hasAccess && (
                            <MarketDropdown
                                markets={markets}
                                market={market}
                                onChange={handleMarketChange}
                            />
                        )}
                        {username && (
                            <Typography sx={autohide}>{username}</Typography>
                        )}
                        {user && (
                            <Tooltip title={L.navbar_logout}>
                                <IconButton onClick={handleLogout}>
                                    <PowerSettingsNewIcon
                                        sx={{ color: 'white' }}
                                    />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Stack>
                </Toolbar>
            </AppBar>
            <Box sx={(theme) => theme.mixins.toolbar} />
        </>
    );
};

export default MenuHeader;
