// Locale reducer

import * as types from '../actions/actionTypes';

const INITIAL_STATE = {
    lang: null,
};

export default function reducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.LOCALE_SET_LANG: {
            return { lang: action.lang };
        }

        default:
            return state;
    }
}
