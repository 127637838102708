// Formatting utilities

/* eslint-disable no-useless-escape */

import dayjs from 'dayjs';

const FMT_DATE = 'DD-MM-YYYY';
const FMT_DATE_ISO = 'YYYY-MM-DD';
const FMT_DATE_TIME = 'DD-MM-YYYY HH:mm:ss';
const REGEXP_DATE = /^(\d+)[-\.\/\s](\d+)[-\.\/\s](\d+)$/;

export function validateDate(str) {
    const d = dayjs(str || '');
    return d.isValid();
}

export function formatDate(str, def = str) {
    return formatDateEx(FMT_DATE, str, def);
}

export function formatDateEx(fmt, str, def = str) {
    const d = dayjs(str || '');
    return d.isValid() ? d.format(fmt) : def;
}

export function formatDateTime(str, def = str) {
    return formatDateTimeEx(FMT_DATE_TIME, str, def);
}

export function formatDateTimeEx(fmt, str, def = str) {
    const d = dayjs(str || '');
    return d.isValid() ? d.format(fmt) : def;
}

export function formatAge(str, unit = 'year') {
    const d = dayjs(str || '');
    return d.isValid() ? dayjs().diff(d, unit) : null;
}

export function formatBool(value, yes, no, nvl = null) {
    return value == null ? nvl : value ? yes : no;
}

export function normalizeDateFormatEx(date, mask = 'DMY') {
    const match = REGEXP_DATE.exec(date);

    if (!Array.isArray(match)) return null;

    const getDatePart = (part) => {
        const idx = mask.indexOf(part);
        return idx < 0 ? null : match[idx + 1];
    };

    const y = +getDatePart('Y');
    const m = +getDatePart('M');
    const d = +getDatePart('D');

    if (!isBetween(y, 1900, 9999)) return null;
    if (!isBetween(m, 1, 12)) return null;
    if (!isBetween(d, 1, 31)) return null;

    return formatDateEx(FMT_DATE_ISO, `${y}-${m}-${d}`, null);
}

export function isBetween(value, min, max) {
    return value >= min && value <= max;
}
