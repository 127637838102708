import LocalizedStrings from 'react-localization';

import en from './messages_en';
import de from './messages_de';
import es from './messages_es';
import pl from './messages_pl';

export const messages = { en, de, es, pl };

export const languages = Object.keys(messages);

export const labels = {
    en: 'English',
    de: 'Deutsch',
    es: 'Español',
    pl: 'Polski',
};

const cache = {};

export function getMessages(lang) {
    let L = cache[lang];
    if (!L) {
        L = new LocalizedStrings(messages);
        L.setLanguage(lang);
        cache[lang] = L;
    }
    return L;
}
