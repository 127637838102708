import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';

import ActionBar from './ActionBar';
import ActionButton from './ActionButton';
import FieldSection from './FieldSection';
import { getFields } from './fields/fieldsTabSystem';

import { useMessages } from '../../../hooks/useMessages';
import {
    dialogShow,
    dialogShowCustom,
} from '../../../store/actions/dialogActions';
import {
    executeConsumerBlock,
    executeConsumerUnblock,
    executeConsumerDelete,
    executeResetPassword,
} from '../../../store/actions/profileActions';

import { FIELDS as F } from '../../../services/consumerFields';
import * as DM from '../../../services/dataModel';
import * as C from '../../../config/marketConfig';

import ConsumerBlockDialog from './ConsumerBlockDialog';
import { getPasswordResetPayload } from '../../../services/consumerApiRequest';

const TabSystem = ({ model }) => {
    const dispatch = useDispatch();
    const { L } = useMessages();

    const { user } = useSelector((state) => state.auth);
    const { market, hasConsumerUpdate } = user || {};

    const sections = useMemo(() => getFields(L, market), [L, market]);

    const canResetMarket = useMemo(
        () => C.getMarketValue(C.MARKET_ACTIONS.resetPassword, market),
        [market]
    );

    const canBlockMarket = useMemo(
        () => C.getMarketValue(C.MARKET_ACTIONS.blockConsumer, market),
        [market]
    );

    const canDeleteMarket = useMemo(
        () => C.getMarketValue(C.MARKET_ACTIONS.deleteConsumer, market),
        [market]
    );

    const isActive = DM.getValue(model, F.IS_ACTIVE_EX);
    const isDeleted = DM.getValue(model, F.IS_DELETED);

    const canReset = canResetMarket && hasConsumerUpdate && !isDeleted;
    const canBlock = canBlockMarket && hasConsumerUpdate && !isDeleted;
    const canDelete = canDeleteMarket && hasConsumerUpdate;

    const handleReset = () => {
        dispatch(
            dialogShow(
                L.dialog_password_reset_title,
                L.dialog_password_reset_text,
                () => {
                    const params = getPasswordResetPayload(model, market);
                    dispatch(executeResetPassword(params));
                }
            )
        );
    };

    const handleBlock = () => {
        dispatch(
            dialogShowCustom(ConsumerBlockDialog, (dialogModel) => {
                const consumerId = DM.getValue(model, F.UID);
                const reason = DM.getValue(dialogModel, F.BLOCKED_REASON);
                dispatch(executeConsumerBlock(consumerId, reason));
            })
        );
    };

    const handleUnblock = () => {
        dispatch(
            dialogShow(
                L.dialog_consumer_unblock_title,
                L.dialog_consumer_unblock_text,
                () => {
                    const consumerId = DM.getValue(model, F.UID);
                    dispatch(executeConsumerUnblock(consumerId));
                }
            )
        );
    };

    const handleDelete = () => {
        dispatch(
            dialogShow(
                L.dialog_consumer_delete_title,
                L.dialog_consumer_delete_text,
                () => {
                    const consumerId = DM.getValue(model, F.UID);
                    dispatch(executeConsumerDelete(consumerId));
                }
            )
        );
    };

    return (
        <Box sx={{ py: 1 }}>
            <ActionBar model={model}>
                {canResetMarket && (
                    <ActionButton disabled={!canReset} onClick={handleReset}>
                        {L.consumerpage_action_btn_reset_password}
                    </ActionButton>
                )}
                {canBlockMarket && (
                    <ActionButton
                        disabled={!canBlock}
                        onClick={isActive ? handleBlock : handleUnblock}
                        color="error"
                    >
                        {isActive
                            ? L.consumerpage_action_btn_block_consumer
                            : L.consumerpage_action_btn_unblock_consumer}
                    </ActionButton>
                )}
                {canDeleteMarket && (
                    <ActionButton
                        disabled={!canDelete}
                        onClick={handleDelete}
                        color="error"
                    >
                        {L.consumerpage_action_btn_delete_consumer}
                    </ActionButton>
                )}
            </ActionBar>
            {sections.map((section, index) => (
                <FieldSection key={index} section={section} model={model} />
            ))}
        </Box>
    );
};

export default TabSystem;
