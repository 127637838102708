import { createTheme } from '@mui/material/styles';
import { enUS, deDE, plPL } from '@mui/material/locale';
import { theme } from './theme';

const locales = {
    DEFAULT: enUS,
    en: enUS,
    de: deDE,
    pl: plPL,
};

export function createLocalizedTheme(lang) {
    const locale = locales[lang] || locales.DEFAULT;
    return createTheme(theme, locale);
}
