// Consumer Search reducer

import * as DM from '../../services/dataModel';
import * as types from '../actions/actionTypes';

const INITIAL_STATE = {
    params: {},
    result: null,
    error: null,
    loading: false,
    page: 0,
    rowsPerPage: 10,
    order: null,
    orderBy: null,
};

const MODEL_FIELDS = new Set([
    'UID',
    'firstName',
    'lastName',
    'dob',
    'email',
    'phoneNumber',
    'addressLine1',
    'city',
    'postal',
]);

export default function searchReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.SEARCH_CHANGE: {
            const { changeType, itemValue } = action;
            let params = state.params;
            if (changeType === 'change') {
                const validate = DM.isErrored(params, itemValue);
                params = DM.setItemValue(params, itemValue, validate);
            } else {
                params = DM.reformatItemValue(params, itemValue);
            }
            return { ...state, params };
        }

        case types.SEARCH_START: {
            const params = state.params || {};
            return {
                ...state,
                params: { ...params, errored: null },
                result: null,
                error: null,
                loading: true,
            };
        }

        case types.SEARCH_RESULT: {
            return {
                ...state,
                result: action.result,
                error: null,
                loading: false,
                page: 0,
            };
        }

        case types.SEARCH_RESULT_UPDATE: {
            const newItem = action.item;
            if (Array.isArray(state.result) && newItem) {
                const result = state.result.map((item) =>
                    item && item.UID === newItem.UID ? newItem : item
                );
                return { ...state, result };
            }
            return state;
        }

        case types.SEARCH_ERROR: {
            const error = action.error;

            if (error?.isValidationError) {
                const { field } = error.details;
                if (MODEL_FIELDS.has(field)) {
                    const params = DM.setFieldError(
                        state.params || {},
                        field,
                        (L) => L.consumerpage_error_rejected_by_server
                    );
                    return {
                        ...state,
                        params,
                        result: null,
                        error: null,
                        loading: false,
                    };
                }
            }

            return {
                ...state,
                result: null,
                error,
                loading: false,
            };
        }

        case types.SEARCH_CLEAR: {
            return {
                ...state,
                params: {},
                result: null,
                error: null,
                page: 0,
                order: null,
                orderBy: null,
            };
        }

        case types.SEARCH_SET_PAGE: {
            return {
                ...state,
                page: action.page,
            };
        }

        case types.SEARCH_SET_ROWS_PER_PAGE: {
            return {
                ...state,
                rowsPerPage: action.rowsPerPage,
            };
        }

        case types.SEARCH_SET_ORDER: {
            return {
                ...state,
                order: action.order,
            };
        }

        case types.SEARCH_SET_ORDER_BY: {
            return {
                ...state,
                orderBy: action.orderBy,
            };
        }

        default:
            return state;
    }
}
