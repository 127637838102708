// Dialog reducer

import * as types from '../actions/actionTypes';

const INITIAL_STATE = {
    isLoading: false,
    brands: null,
    brandsMap: null,
    ts: 0,
};

export default function brandReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.BRAND_SET_DATA: {
            const { brands, brandsMap } = action.data || {};
            const ts = Date.now();
            return { ...state, brands, brandsMap, ts };
        }

        case types.BRAND_SET_LOADING: {
            const { isLoading } = action;
            return { ...state, isLoading };
        }

        case types.BRAND_RESET: {
            return { ...INITIAL_STATE };
        }

        default:
            return state;
    }
}
