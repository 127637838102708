// Dialog reducer

import * as types from '../actions/actionTypes';

const INITIAL_STATE = {
    component: null,
    isOpen: false,
    isLoading: false,
    error: null,
    title: null,
    subTitle: null,
    onConfirm: null,
    onDecline: null,
    options: null,
};

export default function dialogReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.DIALOG_OPEN: {
            return {
                ...INITIAL_STATE,
                component: action.component || null,
                isOpen: true,
                title: action.title || null,
                subTitle: action.subTitle || null,
                onConfirm: action.onConfirm || null,
                onDecline: action.onDecline || null,
                options: action.options || null,
            };
        }

        case types.DIALOG_CLOSE: {
            return {
                ...INITIAL_STATE,
            };
        }

        case types.DIALOG_LOADING: {
            return {
                ...state,
                isLoading: !!action.isLoading,
            };
        }

        case types.DIALOG_ERROR: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }

        default:
            return state;
    }
}
