// Address data tab fields

import { Item as I } from '../../../../services/itemUtils';
import { FIELDS as F } from '../../../../services/consumerFields';
import * as C from '../../../../config/marketConfig';

export function getFields(L, market) {
    const maxLen = C.MV(C.MARKET_FIELDS_MAXLEN.DEFAULT, market);
    const province = C.MV(C.MARKET_FIELDS.addrProvince, market);
    const addrLine1 = C.MV(C.MARKET_FIELDS.addrLine1, market);
    const addrLine2 = C.MV(C.MARKET_FIELDS.addrLine2, market);

    const items = [
        I.new(L.consumerpage_address_field_addr_type, F.ADDR_PERSONAL_TYPE)
            .dropdown(L.dict_address_type)
            .build(),
        I.new(L.consumerpage_address_field_country, F.ADDR_PERSONAL_COUNTRY)
            .editable()
            .required(L.consumerpage_error_required)
            .dropdown(L.dict_country, C.MARKET_VALUES_COUNTRY[market])
            .dropdownValidator(L.consumerpage_error_country)
            .build(),
        I.new(L.consumerpage_address_field_province, F.ADDR_PERSONAL_PROVINCE)
            .visibleIf(province.visible)
            .editable()
            .maxLength(maxLen, L.consumerpage_error_too_long)
            .requiredIf(province.required, L.consumerpage_error_required)
            .regexpValidator(
                C.getMarketValue(C.MARKET_REGEXP_PROVINCE, market),
                L.consumerpage_error_province
            )
            .build(),
        I.new(L.consumerpage_address_field_city, F.ADDR_PERSONAL_CITY)
            .editable()
            .maxLength(maxLen, L.consumerpage_error_too_long)
            .required(L.consumerpage_error_required)
            .regexpValidator(
                C.getMarketValue(C.MARKET_REGEXP_CITY, market),
                L.consumerpage_error_city
            )
            .build(),
        I.new(L.consumerpage_address_field_addr_line_1, F.ADDR_PERSONAL_LINE1)
            .editable()
            .maxLength(maxLen, L.consumerpage_error_too_long)
            .requiredIf(addrLine1.required, L.consumerpage_error_required)
            .build(),
        I.new(L.consumerpage_address_field_addr_line_2, F.ADDR_PERSONAL_LINE2)
            .editable()
            .maxLength(maxLen, L.consumerpage_error_too_long)
            .requiredIf(addrLine2.required, L.consumerpage_error_required)
            .build(),
        I.new(L.consumerpage_address_field_postal, F.ADDR_PERSONAL_POSTAL)
            .editable()
            .maxLength(maxLen, L.consumerpage_error_too_long)
            .requiredIf(
                C.getMarketValue(C.MARKET_FIELDS.addrPostal, market).required,
                L.consumerpage_error_required
            )
            .regexpValidator(
                C.getMarketValue(C.MARKET_REGEXP_POSTAL, market),
                L.consumerpage_error_postal
            )
            .build(),
    ];

    return [{ id: 1, items }];
}
