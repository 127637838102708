// Loyalty tab fields

import { Item as I } from '../../../../services/itemUtils';
import { FIELDS as F } from '../../../../services/consumerFields';
import * as DM from '../../../../services/dataModel';
import * as C from '../../../../config/marketConfig';

import RecalculateTier from '../loyalty/RecalculateTier';

const TAB_FIELDS = {
    summary: getSummaryFields,
    transaction: getTransactionFields,
    auditlog: getAuditlogFields,
};

export function getTabFields(tab, L, market) {
    const getter = TAB_FIELDS[tab];
    return getter ? getter(L, market) : { items: [] };
}

export function getSummaryFields(L) {
    const tierLabelsProvider = (model) => {
        const nextPoints = DM.getValue(
            model,
            F.LOYALTY_SUMMARY_NEXT_TIER_POINTS
        );
        const needsRecalc = Number.isInteger(nextPoints) && nextPoints <= 0;
        return [
            needsRecalc && {
                label: L.consumerpage_loyalty_label_needs_recalc,
            },
            { separator: true },
            { component: RecalculateTier },
        ];
    };

    const items = [
        I.new(
            L.consumerpage_loyalty_field_current_points,
            F.LOYALTY_SUMMARY_CURRENT_POINTS
        ).build(),
        I.new(
            L.consumerpage_loyalty_field_current_tier,
            F.LOYALTY_SUMMARY_CURRENT_TIER
        )
            .labelsProvider(tierLabelsProvider)
            .build(),
        I.new(
            L.consumerpage_loyalty_field_next_tier,
            F.LOYALTY_SUMMARY_NEXT_TIER
        ).build(),
        I.new(
            L.consumerpage_loyalty_field_next_tier_points,
            F.LOYALTY_SUMMARY_NEXT_TIER_POINTS
        ).build(),
    ];

    return { items };
}

export function getTransactionFields(L, market) {
    const items = [
        I.new(L.consumerpage_loyalty_transaction_column_time, 'createdTs')
            .datetime(C.MV(C.MARKET_FORMAT_DATETIME, market))
            .build(),
        I.new(L.consumerpage_loyalty_transaction_column_type, 'transactionType')
            .dropdown(L.dict_loyalty_transaction_type)
            .build(),
        I.new(
            L.consumerpage_loyalty_transaction_column_details,
            'loyaltyEventType'
        ).build(),
        I.new(
            L.consumerpage_loyalty_transaction_column_points,
            'points'
        ).build(),
        I.new(L.consumerpage_loyalty_transaction_column_remarks, 'remark')
            .dropdown(L.dict_loyalty_transaction_remark)
            .build(),
        I.new(
            L.consumerpage_loyalty_transaction_column_created_by,
            'createdBy'
        ).build(),
    ];

    return { items };
}

export function getAuditlogFields(L, market) {
    const items = [
        I.new(L.consumerpage_loyalty_logs_column_time, 'createdTs')
            .datetime(C.MV(C.MARKET_FORMAT_DATETIME, market))
            .build(),
        I.new(
            L.consumerpage_loyalty_logs_column_event_type,
            'loyaltyEventType'
        ).build(),
        I.new(L.consumerpage_loyalty_logs_column_event_id, 'eventId')
            .wrap(true)
            .build(),
        I.new(L.consumerpage_loyalty_logs_column_event_name, 'eventName')
            .wrap(true)
            .build(),
        I.new(
            L.consumerpage_loyalty_logs_column_rule_status,
            'serverExecutionStatus'
        ).build(),
        I.new(L.consumerpage_loyalty_logs_column_rule_type, 'ruleType').build(),
        I.new(
            L.consumerpage_loyalty_logs_column_rule_outcome,
            'outcome'
        ).build(),
        I.new(L.consumerpage_loyalty_logs_column_corelation_id, 'correlationId')
            .wrap(true)
            .build(),
    ];

    return { items };
}
