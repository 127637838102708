import { red, grey, blue, yellow } from '@mui/material/colors';

export const theme = {
    palette: {
        background: {
            default: '#f4f5fd',
        },
        tabHeader: {
            main: blue[100],
        },
        tabHeaderDisabled: {
            main: grey[300],
        },
        tabHeaderDeleted: {
            main: red[100],
        },
        hoverBackground: {
            main: '#e8f5e9',
        },
        inactiveRecord: {
            main: '#c0c0c0',
        },
        changed: {
            main: yellow[50],
        },
    },
};
