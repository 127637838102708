import { useMemo } from 'react';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import FieldItem from './FieldItem';
import { normalizeArray } from '../../../services/objectUtils';

const sxCellHeader = {
    backgroundColor: (theme) => theme.palette.tabHeader.main,
    fontWeight: 'bold',
    padding: '12px',
};

const sxLabel = { ml: 1, fontWeight: 'normal' };

const FieldSection = ({ section, model, onChange }) => {
    const { label, items, getLabels } = section;

    const labels = useMemo(
        () => normalizeArray(getLabels?.(model)).filter((item) => item.label),
        [getLabels, model]
    );

    return (
        <Box
            sx={{
                mb: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    {label && (
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={2} sx={sxCellHeader}>
                                    {label}
                                    {labels.map((item, index) => (
                                        <Chip
                                            key={index}
                                            sx={sxLabel}
                                            size="small"
                                            color={item.color || 'primary'}
                                            label={item.label || ''}
                                        />
                                    ))}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                    )}
                    <TableBody>
                        {items.map((item, index) => (
                            <FieldItem
                                key={index}
                                model={model}
                                item={item}
                                onChange={onChange}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default FieldSection;
