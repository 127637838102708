import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    Typography,
    CircularProgress,
    Stack,
} from '@mui/material';

import { useMessages } from '../../../hooks/useMessages';
import { dialogClose } from '../../../store/actions/dialogActions';
import { Item as I } from '../../../services/itemUtils';
import { FIELDS as F } from '../../../services/consumerFields';
import * as DM from '../../../services/dataModel';
import * as C from '../../../config/marketConfig';
import FieldSection from './FieldSection';

const INITIAL_STATE = { editMode: true };

function getFields(L, market) {
    const items = [
        I.new(L.dialog_consumer_block_reason, F.BLOCKED_REASON)
            .editable()
            .dropdown(
                L.dict_blocking_reason,
                C.MV(C.MARKET_VALUES_BLOCK_REASON, market)
            )
            .build(),
    ];
    return { id: 1, items };
}

const ConsumerBlockDialog = () => {
    const dispatch = useDispatch();
    const { L } = useMessages();
    const [model, setModel] = useState(INITIAL_STATE);

    const dialog = useSelector((state) => state.dialog);
    const { isOpen, isLoading, onConfirm } = dialog;

    const { user } = useSelector((state) => state.auth);
    const { market } = user || {};

    const section = useMemo(() => getFields(L, market), [L, market]);

    // reset state on dialog open/close
    useEffect(() => {
        setModel(INITIAL_STATE);
    }, [setModel, isOpen]);

    const canConfirm = useMemo(
        () => section.items.every((item) => DM.getValue(model, item.field)),
        [section, model]
    );

    const handleChange = (itemValue) => {
        const { item, value } = itemValue;
        setModel((prev) => DM.setFieldValue(prev, item.field, value));
    };

    const onDialogConfirm = () => {
        setModel((prev) => ({ ...prev, editMode: false }));
        onConfirm(model);
    };

    const onDialogClose = () => {
        dispatch(dialogClose());
    };

    return (
        <Dialog open={isOpen} onClose={onDialogClose}>
            <DialogContent
                sx={{ mt: 1, minWidth: '460px', minHeight: '115px' }}
            >
                <Typography variant="h6">
                    {L.dialog_consumer_block_title}
                </Typography>
                <Typography variant="subtitle2" sx={{ mb: 3 }}>
                    {L.dialog_consumer_block_text}
                </Typography>
                <FieldSection
                    section={section}
                    model={model}
                    onChange={handleChange}
                />
            </DialogContent>
            <DialogActions sx={{ mb: 1, mx: 2 }}>
                {isLoading ? (
                    <Stack sx={{ width: '100%', alignItems: 'center' }}>
                        <CircularProgress />
                    </Stack>
                ) : (
                    <>
                        <Button
                            variant="outlined"
                            onClick={onDialogClose}
                            sx={{ minWidth: '5.6rem' }}
                        >
                            {L.dialog_btn_cancel}
                        </Button>
                        <Button
                            variant="contained"
                            color="error"
                            disabled={!canConfirm}
                            onClick={onDialogConfirm}
                            sx={{ minWidth: '5.6rem' }}
                        >
                            {L.dialog_btn_block}
                        </Button>
                    </>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default ConsumerBlockDialog;
