// Consumer API Request utils

import { objectNonEmpty, stringNonEmpty } from './objectUtils';
import { FIELDS as F, GROUPS } from './consumerFields';
import * as DM from './dataModel';
import * as C from '../config/marketConfig';

function getPassResetImpl(cfg, email, mobile) {
    if (cfg?.email && email) return { email };
    if (cfg?.mobile && mobile) return { username: mobile };

    if (cfg?.emailMobile && email) return { email };
    if (cfg?.emailMobile && mobile) return { username: mobile };

    if (cfg?.mobileEmail && mobile) return { username: mobile };
    if (cfg?.mobileEmail && email) return { email };

    return null;
}

export function getPasswordResetPayload(model, market) {
    const cfg = C.MV(C.MARKET_CONTACT, market);
    const email = DM.getValue(model, F.EMAIL);
    const mobile = DM.getValue(model, F.MOBILE_NUMBER);
    return getPassResetImpl(cfg, email, mobile);
}

export function getPasswordResetPayloadSearch(model, market) {
    const cfg = C.MV(C.MARKET_CONTACT, market);
    const email = model.email;
    const mobile = model.phone;
    return getPassResetImpl(cfg, email, mobile);
}

export function getConsumerUpdatePayload(model, market) {
    const { data, changes } = model;

    const N = (value) => DM.getNormalized(value);
    const value = (field) => N(DM.getCurrentValue(model, field));
    const changedAny = (fields) => DM.changedAny(model, fields);

    const payload = {};
    const profile = {};
    const consents = {};
    const favouriteBrand = {};

    // Profile
    const firstName = changes[F.FIRST_NAME];
    const lastName = changes[F.LAST_NAME];
    const dateOfBirth = changes[F.DATE_OF_BIRTH];
    const gender = changes[F.GENDER];
    const email = changes[F.EMAIL];
    const language = changes[F.LANGUAGES];
    const mobile = changes[F.MOBILE_NUMBER];
    const home = data[F.HOME_NUMBER];

    if (firstName) profile.firstName = N(firstName);
    if (lastName) profile.lastName = N(lastName);
    if (dateOfBirth) profile.dateOfBirth = N(dateOfBirth);
    if (gender) profile.gender = N(gender);
    if (email) profile.email = N(email);
    if (language) profile.languages = N(language);

    if (mobile) {
        const phones = [];
        if (stringNonEmpty(N(mobile)))
            phones.push({ type: 'mobile', number: N(mobile) });
        if (stringNonEmpty(N(home)))
            phones.push({ type: 'home', number: N(home) });
        profile.phones = phones;
    }

    // Brands
    if (changedAny(GROUPS.BRAND_SEGMENTATION)) {
        favouriteBrand.brandFamily = value(F.BRAND_FAMILY) || null;
        favouriteBrand.brandFamilyId = value(F.BRAND_FAMILY_ID) || null;
        favouriteBrand.brandSubFamily = value(F.BRAND_SUB_FAMILY) || null;
        favouriteBrand.brandSubFamilyId = value(F.BRAND_SUB_FAMILY_ID) || null;
    }

    // Profile - address
    if (changedAny(GROUPS.ADDR_PERSONAL)) {
        const address = {
            type: 'personal',
            country: value(F.ADDR_PERSONAL_COUNTRY),
            province: value(F.ADDR_PERSONAL_PROVINCE) || undefined,
            city: value(F.ADDR_PERSONAL_CITY) || undefined,
            addressLine1: value(F.ADDR_PERSONAL_LINE1) || undefined,
            addressLine2: value(F.ADDR_PERSONAL_LINE2) || undefined,
            postal: value(F.ADDR_PERSONAL_POSTAL) || undefined,
        };
        profile.address = [address];
    }

    // Consents: channels
    const emailOptIn = changes[F.CHANNEL_OPT_EMAIL];
    const smsOptIn = changes[F.CHANNEL_OPT_SMS];
    const callOptin = changes[F.CHANNEL_OPT_CALL];
    const dmOptIn = changes[F.CHANNEL_OPT_DM];

    if (emailOptIn) consents.emailOptIn = N(emailOptIn);
    if (smsOptIn) consents.smsOptIn = N(smsOptIn);
    if (callOptin) consents.callOptin = N(callOptin);
    if (dmOptIn) consents.dmOptIn = N(dmOptIn);

    // Consents: brands
    const opts = C.getMarketValue(C.MARKET_CONFIG_BRAND_OPTINS, market);

    const brandFields = opts.singleOptin
        ? { [F.BRAND_OPT_FLAG]: opts.brands }
        : opts.brands.reduce(
              (result, brand) =>
                  Object.assign(result, {
                      [F.getBrandOptFlag(brand)]: [brand],
                  }),
              {}
          );

    if (changedAny(Object.keys(brandFields))) {
        const set = new Set();

        Object.entries(brandFields).forEach(([field, brands]) => {
            if (value(field)) brands.forEach(set.add, set);
        });

        const entitlements = Array.from(set).sort();

        consents.brandPreferences = {
            optIn: entitlements.length > 0,
            entitlements,
        };
    }

    // Payload
    if (objectNonEmpty(profile)) payload.profile = profile;
    if (objectNonEmpty(consents)) payload.consents = consents;
    if (objectNonEmpty(favouriteBrand)) payload.favouriteBrand = favouriteBrand;

    return payload;
}
