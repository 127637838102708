import React from 'react';
import { Stack, Grid, Typography } from '@mui/material';

import SearchField from './SearchField';

const SearchSection = ({ section, model, onChange }) => {
    const { label, items } = section;

    return (
        <Grid item md={6} xs={12}>
            <Stack spacing={1}>
                <Typography sx={{ fontSize: '1.2rem' }}>{label}</Typography>
                {items.map((item, index) => (
                    <SearchField
                        key={index}
                        model={model}
                        item={item}
                        onChange={onChange}
                    />
                ))}
            </Stack>
        </Grid>
    );
};

export default SearchSection;
