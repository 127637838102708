import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
    Box,
    CircularProgress,
    Container,
    Paper,
    Button,
    Typography,
} from '@mui/material';
import { useMessages } from '../../../hooks/useMessages';
import { signIn, signOut } from '../../../services/authUtils';
import { isString } from '../../../services/typeUtils';

const ERROR_MESSAGES = {
    NO_USER_SESSION: (L) => L.msg_session_lost,
    USER_SESSION_EXPIRED: (L) => L.msg_session_expired,
};

const HomePage = () => {
    const navigate = useNavigate();
    const { L } = useMessages();
    const { loading, user, error } = useSelector((state) => state.auth);

    const errorMsg = useMemo(
        () => isString(error) && ERROR_MESSAGES[error]?.(L),
        [error, L]
    );

    const handleLogin = () => {
        signIn();
    };

    const handleLogout = () => {
        signOut();
    };

    const handleSearch = () => {
        navigate('/search');
    };

    return (
        <Container>
            <Paper
                sx={{
                    mx: 'auto',
                    mt: 18,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '65%',
                    minHeight: '20rem',
                }}
            >
                <Box
                    component="img"
                    sx={{ mt: 2, mb: 4 }}
                    alt="Logo"
                    src="/logo.svg"
                ></Box>
                <Typography
                    variant="h4"
                    sx={{
                        fontWeight: 500,
                        color: (theme) => theme.palette.primary.main,
                    }}
                >
                    {L.homepage_welcome}
                </Typography>
                {loading ? (
                    <CircularProgress sx={{ mt: 5, mb: 5 }} />
                ) : user && user.hasAccess ? (
                    <Button
                        variant="contained"
                        size="large"
                        sx={{ mt: 5, mb: 5 }}
                        onClick={handleSearch}
                    >
                        {L.homepage_search_btn}
                    </Button>
                ) : user ? (
                    <>
                        <Typography mt={3} color="red">
                            {L.homepage_not_allowed}
                        </Typography>
                        <Button
                            variant="contained"
                            size="large"
                            sx={{ mt: 5, mb: 5 }}
                            onClick={handleLogout}
                        >
                            {L.homepage_logout_btn}
                        </Button>
                    </>
                ) : (
                    <>
                        {errorMsg && (
                            <Typography mt={3} color="red">
                                {errorMsg}
                            </Typography>
                        )}
                        <Button
                            variant="contained"
                            size="large"
                            sx={{ mt: 5, mb: 5 }}
                            onClick={handleLogin}
                        >
                            {L.homepage_login_btn}
                        </Button>
                    </>
                )}
            </Paper>
        </Container>
    );
};

export default HomePage;
