// System tab fields

import { Item as I } from '../../../../services/itemUtils';
import { FIELDS as F } from '../../../../services/consumerFields';
import * as DM from '../../../../services/dataModel';
import * as C from '../../../../config/marketConfig';

export function getFields(L, market) {
    const accountLocked = C.MV(C.MARKET_FIELDS.accountLocked, market);
    const canBlock = C.getMarketValue(C.MARKET_ACTIONS.blockConsumer, market);

    const items1 = [
        I.new(L.consumerpage_system_field_consumer_id, F.UID).build(),
        I.new(L.consumerpage_system_field_created, F.CREATED_DATE)
            .datetime(C.getMarketValue(C.MARKET_FORMAT_DATETIME, market))
            .build(),
    ];

    const items2 = [
        I.new(L.consumerpage_system_field_registered, F.IS_REGISTERED)
            .bool(L.dict_yesno.yes, L.dict_yesno.no)
            .build(),
        I.new(L.consumerpage_system_field_registration_time, F.REGISTERED_DATE)
            .datetime(C.getMarketValue(C.MARKET_FORMAT_DATETIME, market))
            .build(),
        I.new(
            L.consumerpage_system_field_registration_brand,
            F.REGISTRATION_BRAND
        ).build(),
        I.new(
            L.consumerpage_system_field_registration_channel,
            F.REGISTRATION_CHANNEL
        ).build(),
        I.new(
            L.consumerpage_system_field_touchpoint_type,
            F.TOUCHPOINT_TYPE
        ).build(),
        I.new(
            L.consumerpage_system_field_touchpoint_id,
            F.TOUCHPOINT_ID
        ).build(),
    ];

    const items3 = [
        I.new(L.consumerpage_system_field_account_locked, F.IS_LOCKED)
            .visibleIf(accountLocked.visible)
            .bool(L.dict_yesno.yes, L.dict_yesno.no)
            .build(),
        I.new(L.consumerpage_system_field_login_allowed, F.IS_ACTIVE)
            .bool(L.dict_yesno.yes, L.dict_yesno.no)
            .build(),
        I.new(L.consumerpage_system_field_last_login, F.LAST_LOGIN_DATE)
            .datetime(C.getMarketValue(C.MARKET_FORMAT_DATETIME, market))
            .build(),
        I.new(L.consumerpage_system_field_last_updated, F.LAST_UPDATED_DATE)
            .datetime(C.getMarketValue(C.MARKET_FORMAT_DATETIME, market))
            .build(),
        I.new(L.consumerpage_system_field_last_updated_by, F.LAST_UPDATED_BY)
            .dropdown(L.dict_last_updated_by)
            .build(),
    ];

    const itemsBlock = [
        I.new(L.consumerpage_system_field_blockedDate, F.BLOCKED_DATE)
            .datetime(C.getMarketValue(C.MARKET_FORMAT_DATETIME, market))
            .build(),
        I.new(L.consumerpage_system_field_blockedReason, F.BLOCKED_REASON)
            .dropdown(L.dict_blocking_reason)
            .build(),
        I.new(L.consumerpage_system_field_blockedBy, F.BLOCKED_BY).build(),
    ];

    const result = [{ items: items1 }, { items: items2 }, { items: items3 }];

    if (canBlock)
        result.push({
            items: itemsBlock,
            label: L.consumerpage_system_section_blockings,
            getLabels: (model) => {
                const hist = DM.getValue(model, F.BLOCKINGS_IS_HISTORICAL);
                const label =
                    hist && L.consumerpage_system_section_blockings_hist_data;
                return [{ label }];
            },
        });

    return result;
}
