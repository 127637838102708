import React from 'react';
import { useSelector } from 'react-redux';
import {
    Box,
    CircularProgress,
    Container,
    Paper,
    Alert,
    Stack,
} from '@mui/material';
import { useMessages } from '../../../hooks/useMessages';
import SearchResults from './SearchResults';
import SearchForm from './SearchForm';

const sxSearch = { px: 3, py: 3 };
const sxResult = { px: 3, py: 4, alignItems: 'center' };

const SearchPage = () => {
    const { L } = useMessages();
    const search = useSelector((state) => state.search);

    return (
        <Container>
            {search.error && (
                <Alert sx={{ my: 1 }} severity="error">
                    {search.error.message}
                </Alert>
            )}
            <Paper sx={{ my: 2 }} elevation={3}>
                <Box sx={sxSearch}>
                    <SearchForm />
                </Box>
            </Paper>
            {search.loading && (
                <Paper sx={{ my: 2 }} elevation={3}>
                    <Stack sx={sxResult}>
                        <CircularProgress />
                    </Stack>
                </Paper>
            )}
            {search.result && search.result.length > 0 && (
                <Paper sx={{ my: 2 }} elevation={3}>
                    <Stack sx={sxResult}>
                        <SearchResults />
                    </Stack>
                </Paper>
            )}
            {search.result && search.result.length === 0 && (
                <Paper sx={{ my: 2 }} elevation={3}>
                    <Stack sx={sxResult}>
                        {L.searchpage_no_consumers_found}
                    </Stack>
                </Paper>
            )}
        </Container>
    );
};

export default SearchPage;
