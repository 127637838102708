import React from 'react';
import Button from '@mui/material/Button';

function ActionButton({ children, ...attrs }) {
    return (
        <Button
            variant="contained"
            size="small"
            sx={{ minWidth: '5rem' }}
            {...attrs}
        >
            {children}
        </Button>
    );
}

export default ActionButton;
