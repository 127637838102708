import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import CssBaseline from '@mui/material/CssBaseline';

import { createLocalizedTheme } from './styles';
import { useAuthListener } from './hooks/useAuthListener';
import AuthRequired from './components/common/AuthRequired';
import HomePage from './components/pages/HomePage/HomePage';
import SearchPage from './components/pages/SearchPage/SearchPage';
import ConsumerPage from './components/pages/ConsumerPage/ConsumerPage';
import MenuHeader from './components/common/MenuHeader';
import Notification from './components/common/Notification';
import ConfirmDialog from './components/common/ConfirmDialog';

const AppRoot = () => {
    return (
        <>
            <CssBaseline />
            <MenuHeader />
            <Notification />
            <ConfirmDialog />
            <Routes>
                <Route exact path="/" element={<HomePage />} />
                <Route element={<AuthRequired />}>
                    <Route path="/search" element={<SearchPage />} />
                    <Route
                        path="/consumer/:id/:tab"
                        element={<ConsumerPage />}
                    />
                </Route>
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </>
    );
};

const App = () => {
    useAuthListener();

    const { lang } = useSelector((state) => state.locale);
    const theme = useMemo(() => createLocalizedTheme(lang), [lang]);

    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <AppRoot />
            </BrowserRouter>
        </ThemeProvider>
    );
};

export default App;
