import { useCallback } from 'react';
import { TextField } from '@mui/material';

import * as DM from '../../../services/dataModel';
import { ItemValue } from '../../../services/ItemValue';
import { nvl } from '../../../services/typeUtils';
import { useMessages } from '../../../hooks/useMessages';

const noop = () => {};

const SearchField = ({ model, item, onChange }) => {
    const { label, field, editable, autofocus } = item || {};
    const { valueFmt, error, hint } = DM.getDisplayProps(model, item);

    const { getMessage } = useMessages();

    const helperText = nvl(getMessage(error) || getMessage(hint));

    const onChangeHandler = (editable && onChange) || noop;

    const handler = useCallback(
        (e, changeType) => {
            const { value, checked } = e.target;
            const newValue = item.isCheckbox ? checked : value;
            onChangeHandler(changeType, new ItemValue(item, newValue));
        },
        [onChangeHandler, item]
    );

    const handleChange = useCallback((e) => handler(e, 'change'), [handler]);

    const handleBlur = useCallback((e) => handler(e, 'blur'), [handler]);

    const handleKeyPress = useCallback(
        (e) => e.key === 'Enter' && handler(e, 'reformat'),
        [handler]
    );

    if (!item?.visible) return null;

    return (
        <TextField
            autoComplete="off"
            autoFocus={!!autofocus}
            fullWidth
            label={label}
            name={field}
            variant="outlined"
            type="text"
            size="small"
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyPress={handleKeyPress}
            value={valueFmt}
            error={!!error}
            helperText={helperText}
        />
    );
};

export default SearchField;
