// Consumer data model fields

const FIELDS = {
    // System
    UID: 'UID',
    CREATED_DATE: 'createdDate',
    IS_REGISTERED: 'isRegistered',
    REGISTERED_DATE: 'registeredDate',
    IS_LOCKED: 'profile2.isLocked',
    LOCKED_UNTIL: 'lockedUntil',
    REGISTRATION_BRAND: 'profile.registrationBrand',
    REGISTRATION_CHANNEL: 'profile.channelRefCode',
    TOUCHPOINT_TYPE: 'profile.touchpointType',
    TOUCHPOINT_ID: 'profile.touchpointId',
    IS_ACTIVE: 'profile.isActive',
    IS_ACTIVE_EX: 'profile2.isActive',
    IS_DELETED: 'consents2.isDeleted',
    IS_VERIFIED: 'profile.profileVerified',
    LAST_LOGIN_DATE: 'lastLoginDate',
    LAST_UPDATED_DATE: 'lastUpdatedDate',
    LAST_UPDATED_BY: 'lastUpdatedBy',
    // Profile
    FIRST_NAME: 'profile.firstName',
    LAST_NAME: 'profile.lastName',
    DATE_OF_BIRTH: 'profile.dateOfBirth',
    GENDER: 'profile.gender',
    EMAIL: 'profile.email',
    EMAIL_VERIFIED: 'profile2.emailVerified',
    EMAIL_VERIFICATION_STATUS: 'profile2.emailVerificationStatus',
    LANGUAGES: 'profile.languages',
    COUNTRY_CODE: 'profile.countryRefCode',
    PROFILE_VERIFICATION_STATUS: 'profile2.verificationStatus',
    PROFILE_VERIFICATION_DATE: 'profile2.verificationDate',
    // Phones
    MOBILE_NUMBER: 'profile.phones2.mobile',
    HOME_NUMBER: 'profile.phones2.home',
    MOBILE_VERIFICATION_STATUS: 'profile2.mobileVerificationStatus',
    // Address - Personal
    ADDR_PERSONAL_TYPE: 'profile.address2.personal.type',
    ADDR_PERSONAL_COUNTRY: 'profile.address2.personal.country',
    ADDR_PERSONAL_PROVINCE: 'profile.address2.personal.province',
    ADDR_PERSONAL_CITY: 'profile.address2.personal.city',
    ADDR_PERSONAL_LINE1: 'profile.address2.personal.addressLine1',
    ADDR_PERSONAL_LINE2: 'profile.address2.personal.addressLine2',
    ADDR_PERSONAL_POSTAL: 'profile.address2.personal.postal',
    // Channel Opt-ins
    CHANNEL_OPT_DB: 'consents.databaseOptIn',
    CHANNEL_OPT_EMAIL: 'consents.emailOptIn',
    CHANNEL_OPT_SMS: 'consents.smsOptIn',
    CHANNEL_OPT_CALL: 'consents.callOptin',
    CHANNEL_OPT_DM: 'consents.dmOptIn',
    // Brand Opt-ins
    BRAND_OPT_FLAG: 'consents.brandPreferences.optIn',
    getBrandOptFlag: (brand) =>
        `consents2.brands.${String(brand).toLowerCase()}`,
    // Brand segmentation
    BRAND_FAMILY: 'favouriteBrand.brandFamily',
    BRAND_FAMILY_ID: 'favouriteBrand.brandFamilyId',
    BRAND_SUB_FAMILY: 'favouriteBrand.brandSubFamily',
    BRAND_SUB_FAMILY_ID: 'favouriteBrand.brandSubFamilyId',
    // HAV
    HAV_LAST_UPD_DATE: 'hav.hardAVLastUpdatedDate',
    HAV_COUNTRY_CODE: 'hav.hardAVCountryRefCode',
    HAV_TYPE: 'hav.hardAVType',
    HAV_STATUS: 'hav.hardAVStatus',
    // Blockings
    BLOCKINGS_HAS_DATA: 'blockings2.hasBlockingData',
    BLOCKINGS_IS_ACTIVE: 'blockings2.isBlockingActive',
    BLOCKINGS_IS_HISTORICAL: 'blockings2.isBlockingHistorical',
    BLOCKED_BY: 'blockings.blockedBy',
    BLOCKED_REASON: 'blockings.blockingReason',
    BLOCKED_DATE: 'blockings.blockingDate',
    // Loyalty Summary
    LOYALTY_SUMMARY_CURRENT_POINTS: 'currentPointBalance',
    LOYALTY_SUMMARY_CURRENT_TIER: 'currentTierName',
    LOYALTY_SUMMARY_NEXT_TIER: 'nextTierName',
    LOYALTY_SUMMARY_NEXT_TIER_POINTS: 'pointsNeedForNextTier',
    // Loyalty
    LOYALTY_POINTS: 'points',
    LOYALTY_REMARK: 'remark',
};

const GROUPS = {
    ADDR_PERSONAL: [
        FIELDS.ADDR_PERSONAL_TYPE,
        FIELDS.ADDR_PERSONAL_COUNTRY,
        FIELDS.ADDR_PERSONAL_PROVINCE,
        FIELDS.ADDR_PERSONAL_CITY,
        FIELDS.ADDR_PERSONAL_LINE1,
        FIELDS.ADDR_PERSONAL_LINE2,
        FIELDS.ADDR_PERSONAL_POSTAL,
    ],
    BRAND_SEGMENTATION: [
        FIELDS.BRAND_FAMILY,
        FIELDS.BRAND_FAMILY_ID,
        FIELDS.BRAND_SUB_FAMILY,
        FIELDS.BRAND_SUB_FAMILY_ID,
    ],
};

export { FIELDS, GROUPS };
