// Locale actions

import * as types from './actionTypes';
import { getValue, setValue } from '../../services/storageUtils';
import { languages } from '../../l10n';

const DEFAULT_LANG = 'en';

export function setLanguage(lang) {
    const langChecked = languages.includes(lang) ? lang : DEFAULT_LANG;
    setValue('lang', langChecked);
    return { type: types.LOCALE_SET_LANG, lang: langChecked };
}

export function initLanguage() {
    const lang = getValue('lang');
    return setLanguage(lang);
}
