import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';

import ActionBar from './ActionBar';
import ActionButton from './ActionButton';
import FieldSection from './FieldSection';

import { useMessages } from '../../../hooks/useMessages';
import { executeAvSet } from '../../../store/actions/profileActions';
import { dialogShow } from '../../../store/actions/dialogActions';
import { FIELDS as F } from '../../../services/consumerFields';
import * as DM from '../../../services/dataModel';
import * as C from '../../../config/marketConfig';
import { toUpperCase } from '../../../services/typeUtils';
import { getFields } from './fields/fieldsTabAgeVerification';

const TabAgeVerification = ({ model }) => {
    const { user } = useSelector((state) => state.auth);
    const { market, hasHavUpdate } = user || {};

    const dispatch = useDispatch();
    const { id: consumerId } = useParams();
    const { L } = useMessages();

    const sections = useMemo(() => getFields(L, market), [L, market]);

    const canSetHavMarket = useMemo(
        () => C.MV(C.MARKET_ACTIONS.havUpdate, market),
        [market]
    );

    const softMethods = useMemo(
        () => new Set(C.MV(C.MARKET_VALUES_AV_SOFT_METHODS, market)),
        [market]
    );

    const setHavConfirmation = useMemo(
        () => C.MV(C.MARKET_LABELS.setHavConfirmation, market, L),
        [market, L]
    );

    const isVerified = DM.getValue(model, F.HAV_STATUS) === 'VERIFIED';
    const isSoftMethod = softMethods.has(
        toUpperCase(DM.getValue(model, F.HAV_TYPE))
    );

    const isHardVerified = isVerified && !isSoftMethod;

    const canSetHav = canSetHavMarket && hasHavUpdate && !isHardVerified;

    const handleSetHav = useCallback(() => {
        const doAction = () => dispatch(executeAvSet(consumerId));

        dispatch(
            dialogShow(
                L.dialog_set_hav_status_title,
                L.dialog_set_hav_status_text,
                () => {
                    if (!setHavConfirmation) {
                        return doAction();
                    }
                    // double confirmation
                    dispatch(
                        dialogShow(
                            L.dialog_set_hav_status_title,
                            setHavConfirmation,
                            doAction,
                            null,
                            {
                                confirmText: L.dialog_btn_yes_confirm,
                                declineText: L.dialog_btn_cancel,
                            }
                        )
                    );
                }
            )
        );
    }, [dispatch, L, consumerId, setHavConfirmation]);

    return (
        <Box sx={{ py: 1 }}>
            <ActionBar model={model}>
                {canSetHavMarket && (
                    <ActionButton disabled={!canSetHav} onClick={handleSetHav}>
                        {L.consumerpage_action_btn_set_hav_status}
                    </ActionButton>
                )}
            </ActionBar>
            {sections.map((section, index) => (
                <FieldSection key={index} section={section} model={model} />
            ))}
        </Box>
    );
};

export default TabAgeVerification;
