// Object utilities

import flat from 'flat';

export function flatten(data) {
    return data != null ? flat.flatten(data, { safe: true }) : null;
}

export function unflatten(data) {
    return data != null ? flat.unflatten(data, { safe: true }) : null;
}

export function normalizeArray(data) {
    const arr = Array.isArray(data) ? data : [data];
    return arr.filter((item) => item != null);
}

export function objectNonEmpty(data) {
    return data != null && Object.keys(data).length > 0;
}

export function stringNonEmpty(data) {
    return data != null && String(data).length > 0;
}

export function filterUnique(getKey = (item) => item) {
    const keys = new Set();
    return function filter(item) {
        const key = getKey(item);
        if (keys.has(key)) return false;
        keys.add(key);
        return true;
    };
}

export function deepClone(data) {
    if (data != null) data = JSON.parse(JSON.stringify(data)); // simple deep clone
    return data;
}
