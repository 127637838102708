import React, { useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Stack, Button, Box, Grid } from '@mui/material';

import { useMessages } from '../../../hooks/useMessages';
import {
    executeSearch,
    searchChange,
    searchClear,
} from '../../../store/actions/searchActions';
import * as DM from '../../../services/dataModel';
import SearchSection from './SearchSection';
import { getFields } from './fields';

const SearchForm = () => {
    const dispatch = useDispatch();
    const { L } = useMessages();

    const { user } = useSelector((state) => state.auth);
    const { market } = user || {};

    const sections = useMemo(() => getFields(L, market), [L, market]);

    const search = useSelector((state) => state.search);
    const { params: model } = search;

    const searchHandler = useCallback(
        (e) => {
            e.preventDefault();
            const params = DM.getChangesNormalized(model);
            dispatch(executeSearch(params));
        },
        [dispatch, model]
    );

    const clearHandler = useCallback(() => {
        dispatch(searchClear());
    }, [dispatch]);

    const handleChange = useCallback(
        (changeType, itemValue) => {
            dispatch(searchChange(changeType, itemValue));
        },
        [dispatch]
    );

    const hasChanges = useMemo(() => DM.hasChanges(model), [model]);
    const hasErrors = useMemo(() => DM.hasErrors(model), [model]);

    const canSearch = !search.loading && hasChanges && !hasErrors;
    const canClear = !search.loading && hasChanges;

    return (
        <Box component="form" noValidate autoComplete="off">
            <Grid container spacing={2}>
                {sections.map((section, index) => (
                    <SearchSection
                        key={index}
                        section={section}
                        model={model}
                        onChange={handleChange}
                    />
                ))}
            </Grid>
            <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
                <Button
                    variant="contained"
                    onClick={searchHandler}
                    disabled={!canSearch}
                    color="primary"
                    type="submit"
                >
                    {L.searchpage_btn_search}
                </Button>
                <Button
                    variant="outlined"
                    onClick={clearHandler}
                    disabled={!canClear}
                >
                    {L.searchpage_btn_clear}
                </Button>
            </Stack>
        </Box>
    );
};

export default SearchForm;
