import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';

import { useMessages } from '../../../hooks/useMessages';
import { useEditMode } from './useEditMode';
import ActionBar from './ActionBar';
import FieldSection from './FieldSection';
import { getFields } from './fields/fieldsTabPersonal';

const TabPersonal = ({ model }) => {
    const { L } = useMessages();
    const { renderEditButtons, handleChange } = useEditMode(model);

    const { user } = useSelector((state) => state.auth);
    const { market } = user || {};

    const sections = useMemo(
        () => getFields(L, market, model),
        [L, market, model]
    );

    return (
        <Box sx={{ py: 1 }}>
            <ActionBar model={model}>{renderEditButtons()}</ActionBar>
            {sections.map((section, index) => (
                <FieldSection
                    key={index}
                    section={section}
                    model={model}
                    onChange={handleChange}
                />
            ))}
        </Box>
    );
};

export default TabPersonal;
