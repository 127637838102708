// Search fields

/* eslint-disable no-useless-escape */

import { Item as I } from '../../../services/itemUtils';
import * as C from '../../../config/marketConfig';

const REGEXP_UID = /^[A-Za-z0-9]+$/;
const REGEXP_EMAIL = /^[^\s@]+@[^\s\.@]+(\.[^\s\.@]+)+$/;
const REGEXP_PHONE = /^\+?\d+$/;

const MAXLEN = {
    UID: 55,
    firstName: 50,
    lastName: 50,
    email: 80,
    phoneNumber: 16,
    addressLine1: 100,
    city: 40,
    postal: 15,
};

function isAddressValid(value) {
    const err = value.indexOf('*') >= 0 && value.length > 1;
    return !err;
}

export function getFields(L, market) {
    const dateFormat = C.MV(C.MARKET_FORMAT_DATE, market);

    const uidValidator = (value) =>
        !REGEXP_UID.test(value) && L.searchpage_invalid_uid;

    const emailValidator = (value) =>
        !REGEXP_EMAIL.test(value) && L.searchpage_invalid_email;

    const phoneNumberValidator = (value) =>
        !REGEXP_PHONE.test(value) && L.searchpage_invalid_phone;

    const addressValidator = (value) =>
        !isAddressValid(value) && L.searchpage_invalid_wildcard;

    const personItems = [
        I.new(L.searchpage_field_first_name, 'firstName')
            .autofocus()
            .editable()
            .maxLength(MAXLEN.firstName, L.consumerpage_error_too_long)
            .build(),
        I.new(L.searchpage_field_last_name, 'lastName')
            .editable()
            .maxLength(MAXLEN.lastName, L.consumerpage_error_too_long)
            .build(),
        I.new(L.formatString(L.searchpage_field_dob, dateFormat.fmt), 'dob')
            .editable()
            .date(dateFormat)
            .normalizeValidator(L.searchpage_invalid_date)
            .build(),
        I.new(L.searchpage_field_email, 'email')
            .editable()
            .maxLength(MAXLEN.email, L.consumerpage_error_too_long)
            .validator(emailValidator)
            .build(),
        I.new(L.searchpage_field_phone, 'phoneNumber')
            .editable()
            .maxLength(MAXLEN.phoneNumber, L.consumerpage_error_too_long)
            .validator(phoneNumberValidator)
            .build(),
        I.new(L.searchpage_field_uid, 'UID')
            .editable()
            .maxLength(MAXLEN.UID, L.consumerpage_error_too_long)
            .validator(uidValidator)
            .build(),
    ];

    const addressItems = [
        I.new(L.searchpage_field_address, 'addressLine1')
            .editable()
            .maxLength(MAXLEN.addressLine1, L.consumerpage_error_too_long)
            .validator(addressValidator)
            .build(),
        I.new(L.searchpage_field_city, 'city')
            .editable()
            .maxLength(MAXLEN.city, L.consumerpage_error_too_long)
            .validator(addressValidator)
            .build(),
        I.new(L.searchpage_field_postal, 'postal')
            .editable()
            .maxLength(MAXLEN.postal, L.consumerpage_error_too_long)
            .validator(addressValidator)
            .build(),
    ];

    return [
        { id: 1, items: personItems, label: L.searchpage_section_person },
        { id: 2, items: addressItems, label: L.searchpage_section_address },
    ];
}
