import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import * as DM from '../../../services/dataModel';
import FieldValue from './FieldValue';

const contentCell = {
    width: '50%',
    fontWeight: 'bold',
};

const contentCheckbox = {
    ...contentCell,
    padding: '8px',
};

const rowStyle = (changed) => ({
    cursor: 'pointer',
    backgroundColor: (theme) =>
        changed ? theme.palette.changed.main : theme.palette.background.paper,
    '&:hover': {
        backgroundColor: (theme) =>
            changed
                ? theme.palette.changed.main
                : theme.palette.hoverBackground.main,
    },
});

const FieldItem = ({ model, item, onChange }) => {
    const { label, isCheckbox, showChanges } = item || {};

    const { isChanged } = DM.getDisplayProps(model, item);

    if (!item?.visible) return null;

    return (
        <TableRow sx={rowStyle(isChanged && showChanges)}>
            <TableCell style={contentCell}>{label}</TableCell>
            <TableCell style={isCheckbox ? contentCheckbox : contentCell}>
                <FieldValue model={model} item={item} onChange={onChange} />
            </TableCell>
        </TableRow>
    );
};

export default FieldItem;
