import React from 'react';
import ReactDOM from 'react-dom';
import TTI from 'tti-polyfill';
import './index.css';
import App from './App';
import { Amplify } from 'aws-amplify';
import config from './config';
import { Provider as ReduxProvider } from 'react-redux';
import configureStore from './store/configureStore';

Amplify.configure(config);

const store = configureStore();

ReactDOM.render(
    <ReduxProvider store={store}>
        <React.StrictMode>
            <App />
        </React.StrictMode>
    </ReduxProvider>,
    document.getElementById('root')
);

// TTI metric
TTI.getFirstConsistentlyInteractive().then((tti) => {
    if (window.newrelic != null && tti != null) {
        window.newrelic.addPageAction('TimeToInteractive', { time: tti });
    }
});
