import React, { useCallback, useMemo, useState } from 'react';
import { Button, Menu, MenuItem, Typography } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useMessages } from '../../hooks/useMessages';

const upper = (value) => String(value).toUpperCase();

function LanguageDropdown() {
    const { L, language, languages, labels, setLanguage } = useMessages();
    const [anchorEl, setAnchorEl] = useState(null);

    const lang = upper(language);
    const open = Boolean(anchorEl);
    const icon = open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />;

    const getLabel = useCallback(
        (lang) => `${labels[lang] || upper(lang)} (${upper(lang)})`,
        [labels]
    );

    const options = useMemo(() => {
        return languages.map((market) => ({
            value: market,
            label: getLabel(market),
        }));
    }, [languages, getLabel]);

    const hasOptions = options.length > 1;

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (lang) => {
        setAnchorEl(null);
        setLanguage(lang);
    };

    return !hasOptions ? (
        <Typography sx={{ pr: 1 }}>{lang}</Typography>
    ) : (
        <>
            <Button
                onClick={handleOpen}
                endIcon={icon}
                sx={{
                    color: 'inherit',
                    textTransform: 'none',
                }}
            >
                <Typography>{lang}</Typography>
            </Button>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem
                    disabled={true}
                    divider={true}
                    sx={{ fontStyle: 'italic' }}
                >
                    {L.navbar_language_choose_language}
                </MenuItem>
                {options.map((item) => (
                    <MenuItem
                        key={item.value}
                        onClick={() => handleClick(item.value)}
                    >
                        {item.label}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}

export default LanguageDropdown;
