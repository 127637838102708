import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { useState } from 'react';

const AsyncDropdown = (props) => {
    const {
        options = [],
        label,
        loading = false,
        onOpen = () => {},
        onClose = () => {},
        error = null,
    } = props;
    const [open, setOpen] = useState(false);

    return (
        <Autocomplete
            {...props}
            open={open}
            options={options}
            loading={loading}
            onOpen={() => {
                onOpen();
                setOpen(true);
            }}
            onClose={() => {
                onClose();
                setOpen(false);
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    placeholder={label}
                    variant="standard"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? (
                                    <CircularProgress
                                        color="inherit"
                                        size={20}
                                    />
                                ) : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                    error={!!error}
                    helperText={error}
                />
            )}
        />
    );
};

export default AsyncDropdown;
