// Data Model utils

import { ItemValue } from './ItemValue';
import { normalizeArray, objectNonEmpty } from './objectUtils';
import { nvl } from './typeUtils';

function getField(value) {
    return value instanceof ItemValue ? value.item?.field : value;
}

export function getDisplayProps(state, item) {
    const { field, format, isDropdown } = item;

    const val = getCurrentValue(state, field);
    const value = nvl(val instanceof ItemValue ? val.value : val);
    const valueFmt = nvl(
        val instanceof ItemValue
            ? isDropdown
                ? format(val.value) // for drop-downs, ItemValue is not formatted
                : val.value
            : format(val)
    );
    const error = nvl(getError(state, field));
    const hint = nvl(getHint(state, field));
    const changed = isChanged(state, field);

    return { value, valueFmt, error, hint, isChanged: changed };
}

export function getValue(state, field) {
    return state?.data?.[field];
}

export function getCurrentValue(state, field) {
    const value1 = state?.data?.[field];
    const value2 = state?.changes?.[field];
    return nvl(value2, value1);
}

export function getError(state, field) {
    return state?.errors?.[field];
}

export function getHint(state, field) {
    return state?.hints?.[field];
}

export function isChanged(state, field) {
    return state?.changes?.[field] != null;
}

export function changedAny(state, fields) {
    return fields.some((field) => isChanged(state, field));
}

export function isValid(state, field) {
    return state?.errors?.[getField(field)] == null;
}

export function isErrored(state, field) {
    return !!state?.errored?.[getField(field)];
}

export function hasChanges(state) {
    return objectNonEmpty(state?.changes);
}

export function hasErrors(state) {
    return objectNonEmpty(state?.errors);
}

export function getNormalized(value) {
    return value instanceof ItemValue ? value.normalized : value;
}

export function getChangesNormalized(state) {
    return Object.entries(state?.changes || {}).reduce(
        (result, [key, value]) => {
            result[key] = getNormalized(value);
            return result;
        },
        {}
    );
}

export function setFieldValue(state, field, value) {
    let data = state.data || {};
    data = { ...data, [field]: value };
    return { ...state, data };
}

export function setFieldError(state, field, error) {
    let errors = state.errors || {};
    let errored = state.errored || {};

    if (!error) {
        delete errors[field];
    } else {
        errors = { ...errors, [field]: error };
        errored = { ...errored, [field]: true };
    }

    return { ...state, errors, errored };
}

export function setFieldErrors(state, fieldErrors) {
    normalizeArray(fieldErrors).forEach(({ field, error }) => {
        if (field) state = setFieldError(state, field, error);
    });
    return state;
}

export function setFieldHint(state, field, hint) {
    let hints = state.hints || {};

    if (!hint) {
        delete hints[field];
    } else {
        hints = { ...hints, [field]: hint };
    }

    return { ...state, hints };
}

export function checkFieldRequired(state, item) {
    const { field, required, requiredMsg, isDropdown, validator } = item;

    if (!required) return state;

    const value = getCurrentValue(state, field);
    const emptyValue = value == null || value === '';

    if (emptyValue) {
        const error = requiredMsg || 'Required.';
        return setFieldError(state, field, error);
    }

    if (isDropdown) {
        const error = validator && validator(value);
        if (error) return setFieldError(state, field, error);
    }

    return state;
}

export function setItemValue(state, itemValue, validate = true) {
    const { item, value } = itemValue;
    const { field, required, requiredMsg, isDropdown, format } = item;
    const { validator, hinter } = item;

    const emptyValue = value === '';
    const falseValue = value === false;

    const oldValue = state.data?.[field];
    const oldValueFmt = isDropdown ? oldValue : format(oldValue);
    const sameValue =
        oldValueFmt === value ||
        (oldValue == null && falseValue) ||
        (oldValue == null && emptyValue);

    const changes = state.changes || {};
    const newChanges = { ...changes, [field]: itemValue };
    if (sameValue) delete newChanges[field];

    state = { ...state, changes: newChanges };

    if (!validate) return state;

    let error = null;
    if (!error && required && emptyValue) error = requiredMsg || 'Required.';
    if (!error && validator && !emptyValue) error = validator(value);

    let hint = null;
    if (!error) hint = hinter && hinter(value);

    state = setFieldError(state, field, error);
    state = setFieldHint(state, field, hint);

    return state;
}

export function reformatItemValue(state, itemValue) {
    const { item, value } = itemValue;

    state = setItemValue(state, itemValue, true);

    if (isValid(state, itemValue)) {
        const reformatted = new ItemValue(item, nvl(item.reformat(value)));
        state = setItemValue(state, reformatted, true);
    }

    return state;
}
