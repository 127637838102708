import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Typography,
    CircularProgress,
    Stack,
} from '@mui/material';

import { useMessages } from '../../../../hooks/useMessages';
import { dialogClose } from '../../../../store/actions/dialogActions';
import { Item as I } from '../../../../services/itemUtils';
import { FIELDS as F } from '../../../../services/consumerFields';
import { normalizeArray } from '../../../../services/objectUtils';
import * as DM from '../../../../services/dataModel';
import FieldSection from '../FieldSection';

const INITIAL_STATE = { editMode: true };

const MIN_POINTS = 1;
const MAX_POINTS = 1000;

const MODEL_FIELDS = {
    'body.points': 'points',
    'body.remark': 'remark',
};

function getFields(L) {
    const items = [
        I.new(L.dialog_assign_points_input_point_label, F.LOYALTY_POINTS)
            .autofocus()
            .editable()
            .required(L.consumerpage_error_required)
            .numberValidator(
                L.dialog_assign_points_invalid_points,
                MIN_POINTS,
                MAX_POINTS
            )
            .build(),
        I.new(L.dialog_assign_points_input_remark_label, F.LOYALTY_REMARK)
            .editable()
            .required(L.consumerpage_error_required)
            .dropdown(L.dict_loyalty_transaction_remark)
            .build(),
    ];
    return { id: 1, items };
}

const AssignPointsDialog = () => {
    const dispatch = useDispatch();
    const { L } = useMessages();
    const [model, setModel] = useState(INITIAL_STATE);

    const dialog = useSelector((state) => state.dialog);
    const { isOpen, isLoading, error, onConfirm } = dialog;

    const section = useMemo(() => getFields(L), [L]);

    // reset state on dialog open/close
    useEffect(() => {
        setModel(INITIAL_STATE);
    }, [setModel, isOpen]);

    // handle the error
    useEffect(() => {
        if (isOpen && error) {
            const isValidationError =
                error.details?.info === 'VALIDATION_ERROR';

            if (isValidationError) {
                const validationErrors = normalizeArray(error.details?.details);
                const fieldErrors = validationErrors.map((err) => ({
                    field: MODEL_FIELDS[err.field],
                    error: (L) => L.consumerpage_error_rejected_by_server,
                }));
                setModel((prev) => DM.setFieldErrors(prev, fieldErrors));
            }

            setModel((prev) => ({ ...prev, editMode: true }));
        }
    }, [setModel, isOpen, error]);

    const canConfirm = useMemo(
        () =>
            section.items.every(
                (item) =>
                    DM.getCurrentValue(model, item.field) &&
                    DM.isValid(model, item.field)
            ),
        [section, model]
    );

    const handleChange = (itemValue) => {
        setModel((prev) => DM.setItemValue(prev, itemValue));
    };

    const onDialogConfirm = () => {
        setModel((prev) => ({ ...prev, editMode: false }));
        onConfirm(model);
    };

    const onDialogClose = () => {
        dispatch(dialogClose());
    };

    return (
        <Dialog open={isOpen} onClose={onDialogClose}>
            <DialogContent
                sx={{ mt: 1, minWidth: '500px', minHeight: '115px' }}
            >
                <Typography variant="h6">
                    {L.dialog_assign_points_title}
                </Typography>
                <Typography variant="subtitle2" sx={{ mt: 1, mb: 3 }}>
                    {L.dialog_assign_points_subtitle}
                </Typography>
                <FieldSection
                    section={section}
                    model={model}
                    onChange={handleChange}
                />
            </DialogContent>
            <DialogActions sx={{ mb: 1, mx: 2 }}>
                {isLoading ? (
                    <Stack sx={{ width: '100%', alignItems: 'center' }}>
                        <CircularProgress />
                    </Stack>
                ) : (
                    <>
                        <Button
                            variant="outlined"
                            onClick={onDialogClose}
                            sx={{ minWidth: '5.6rem' }}
                        >
                            {L.dialog_btn_cancel}
                        </Button>
                        <Button
                            variant="contained"
                            color="error"
                            disabled={!canConfirm}
                            onClick={onDialogConfirm}
                            sx={{ minWidth: '5.6rem' }}
                        >
                            {L.dialog_assign_points_cta_update_points}
                        </Button>
                    </>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default AssignPointsDialog;
