// English translations

const messages = {
    // Navbar
    navbar_admin_portal: 'Admin Portal',
    navbar_consumer_search: 'Consumer Search',
    navbar_language_choose_language: 'Choose language',
    navbar_market_choose_market: 'Choose market',
    navbar_logout: 'Logout',

    // Home page
    homepage_welcome: 'Welcome to Admin Portal',
    homepage_not_allowed: 'You are not allowed to use this application.',
    homepage_search_btn: 'Consumer Search',
    homepage_login_btn: 'Login',
    homepage_logout_btn: 'Logout',

    // Search page
    searchpage_no_consumers_found: 'No consumers found.',
    searchpage_section_person: 'Person',
    searchpage_section_address: 'Address',
    searchpage_btn_search: 'Search',
    searchpage_btn_clear: 'Clear',
    searchpage_field_uid: 'Consumer ID',
    searchpage_field_first_name: 'First name',
    searchpage_field_last_name: 'Last name',
    searchpage_field_dob: 'Date of birth ({0})',
    searchpage_field_email: 'Email address',
    searchpage_field_phone: 'Phone number',
    searchpage_field_address: 'Address',
    searchpage_field_city: 'City',
    searchpage_field_postal: 'Postal code',
    searchpage_invalid_uid: 'Invalid Consumer ID.',
    searchpage_invalid_date: 'Invalid date.',
    searchpage_invalid_email: 'Invalid email.',
    searchpage_invalid_phone: 'Invalid phone number.',
    searchpage_invalid_wildcard:
        'Invalid wildcard (*) usage. Single wildcard allowed when used alone.',
    searchpage_column_first_name: 'First Name',
    searchpage_column_last_name: 'Last Name',
    searchpage_column_address: 'Address',
    searchpage_column_dob: 'Date of Birth',
    searchpage_column_email: 'Email',
    searchpage_column_phone: 'Phone Number',
    searchpage_column_actions: 'Actions',
    searchpage_menu_view_profile: 'View Profile',
    searchpage_menu_age_verification: 'Age Verification',
    searchpage_menu_marketing_prefs: 'Marketing Preferences',
    searchpage_menu_password_reset: 'Password Reset',
    searchpage_menu_consumer_delete: 'Consumer Delete',

    // Consumer page: tab names
    consumerpage_tab_system: 'System',
    consumerpage_tab_personal: 'Personal',
    consumerpage_tab_address: 'Address Data',
    consumerpage_tab_marketing_prefs: 'Marketing Preferences',
    consumerpage_tab_age_verification: 'Age Verification',
    consumerpage_tab_loyalty_data: 'Loyalty Programs',

    // Consumer page: action buttons
    consumerpage_action_label_disabled: 'Disabled',
    consumerpage_action_label_deleted: 'Deleted',
    consumerpage_action_label_locked: 'Locked',
    consumerpage_action_nav_back: 'Back to Search',
    consumerpage_action_btn_edit: 'Edit',
    consumerpage_action_btn_save: 'Save',
    consumerpage_action_btn_cancel: 'Cancel',
    consumerpage_action_btn_reset_password: 'Reset Password',
    consumerpage_action_btn_block_consumer: 'Block Consumer',
    consumerpage_action_btn_unblock_consumer: 'Unblock Consumer',
    consumerpage_action_btn_delete_consumer: 'Delete Consumer',
    consumerpage_action_btn_set_hav_status: 'Set HAV Status',
    consumerpage_action_btn_assign_points: 'Assign Points',

    // Consumer page: validation errors
    consumerpage_error_rejected_by_server: 'Value rejected by server.',
    consumerpage_error_required: 'Field is required.',
    consumerpage_error_too_long: 'Value too long.',
    consumerpage_error_date: 'Invalid date.',
    consumerpage_error_date_in_future: 'Date in the future.',
    consumerpage_error_age_under_18: 'Note: Age under 18.',
    consumerpage_error_email: 'Invalid email.',
    consumerpage_error_phone: 'Invalid phone number.',
    consumerpage_error_country: 'Invalid country.',
    consumerpage_error_province: 'Invalid province.',
    consumerpage_error_city: 'Invalid city.',
    consumerpage_error_postal: 'Invalid postal code.',

    // Consumer page: System tab
    consumerpage_system_section_blockings: 'Blockings',
    consumerpage_system_section_blockings_hist_data: 'Historical Data',
    consumerpage_system_field_consumer_id: 'Consumer ID',
    consumerpage_system_field_created: 'Created',
    consumerpage_system_field_registered: 'Registered',
    consumerpage_system_field_registration_time: 'Registration Time',
    consumerpage_system_field_registration_brand: 'Registration Brand',
    consumerpage_system_field_registration_channel: 'Registration Channel',
    consumerpage_system_field_touchpoint_type: 'Touchpoint Type',
    consumerpage_system_field_touchpoint_id: 'Touchpoint ID',
    consumerpage_system_field_account_locked: 'Account Locked',
    consumerpage_system_field_login_allowed: 'Login Allowed',
    consumerpage_system_field_last_login: 'Last Login',
    consumerpage_system_field_last_updated: 'Last Updated',
    consumerpage_system_field_last_updated_by: 'Last Updated By',
    consumerpage_system_field_blockedBy: 'Blocked By',
    consumerpage_system_field_blockedReason: 'Blocked Reason',
    consumerpage_system_field_blockedDate: 'Blocked Date',

    // Consumer page: Personal tab
    consumerpage_personal_field_first_name: 'First Name',
    consumerpage_personal_field_last_name: 'Last Name',
    consumerpage_personal_field_full_name: 'Name',
    consumerpage_personal_field_dob: 'Date of Birth',
    consumerpage_personal_field_gender: 'Gender',
    consumerpage_personal_field_email: 'Email',
    consumerpage_personal_field_language: 'Language',
    consumerpage_personal_field_home_country: 'Home Country',
    consumerpage_personal_field_mobile_phone: 'Mobile Phone',
    consumerpage_personal_field_home_phone: 'Home Phone',
    consumerpage_personal_field_mobile_verification_status:
        'Mobile Number Verification Status',
    consumerpage_personal_field_mobile_verification_date:
        'Mobile Number Verification Date',

    // Consumer page: Address tab
    consumerpage_address_field_addr_type: 'Address Type',
    consumerpage_address_field_country: 'Country',
    consumerpage_address_field_province: 'Province',
    consumerpage_address_field_city: 'City',
    consumerpage_address_field_addr_line_1: 'Address Line 1',
    consumerpage_address_field_addr_line_2: 'Address Line 2',
    consumerpage_address_field_postal: 'Postal Code',

    // Consumer page: Marketing Prefs tab
    consumerpage_marketing_section_channel: 'Channel Opt-ins',
    consumerpage_marketing_section_brand: 'Brand Opt-ins',
    consumerpage_marketing_section_brand_seg: 'Brand Segmentation',
    consumerpage_marketing_section_brand_pref: 'Brand Preferences',
    consumerpage_marketing_field_opt_email: 'Receive Email',
    consumerpage_marketing_field_opt_sms: 'Receive SMS',
    consumerpage_marketing_field_opt_calls: 'Receive Phone Calls',
    consumerpage_marketing_field_opt_dm: 'Receive Postal Mail',
    consumerpage_marketing_field_opt_brand: 'Receive Brand Communication',
    consumerpage_marketing_field_brand: 'Brand',
    consumerpage_marketing_field_subbrand: 'Sub Brand',
    consumerpage_marketing_msg_choose_brand: 'Choose Brand',
    consumerpage_marketing_msg_choose_subbrand: 'Choose Sub Brand',
    consumerpage_marketing_msg_invalid_brand: '<Invalid Brand>',
    consumerpage_marketing_msg_invalid_subbrand: '<Invalid Sub Brand>',

    // Consumer page: Age Verification tab
    consumerpage_age_section_label_hav: 'Age Verification (HAV)',
    consumerpage_age_field_dob: 'Date of Birth',
    consumerpage_age_field_home_country: 'Home Country',
    consumerpage_age_field_hav_last_updated: 'Last Updated',
    consumerpage_age_field_hav_country: 'Country',
    consumerpage_age_field_hav_type: 'Type',
    consumerpage_age_field_hav_status: 'Status',

    // Consumer page: Loyalty Programs tab
    consumerpage_loyalty_msg_consumer_not_registered:
        'Consumer is not enrolled in loyalty program.',

    consumerpage_loyalty_field_current_points: 'Current Points',
    consumerpage_loyalty_field_current_tier: 'Current Tier',
    consumerpage_loyalty_field_next_tier: 'Next Tier',
    consumerpage_loyalty_field_next_tier_points: 'Points Needed For Next Tier',
    consumerpage_loyalty_field_brand: 'Select Brand',

    consumerpage_loyalty_label_needs_recalc: 'NEEDS RECALCULATION',

    consumerpage_loyalty_btn_recalc: 'Recalculate',
    consumerpage_loyalty_btn_recalc_requested: 'Requested',

    consumerpage_loyalty_tab_transactions: 'Transaction History',
    consumerpage_loyalty_tab_auditlogs: 'Audit Log',

    consumerpage_loyalty_transaction_column_time: 'Transaction Time',
    consumerpage_loyalty_transaction_column_type: 'Type',
    consumerpage_loyalty_transaction_column_details: 'Details',
    consumerpage_loyalty_transaction_column_points: 'Points',
    consumerpage_loyalty_transaction_column_remarks: 'Remarks',
    consumerpage_loyalty_transaction_column_created_by: 'Created By',

    consumerpage_loyalty_logs_column_time: 'Log Time',
    consumerpage_loyalty_logs_column_rule_status: 'Rule Status',
    consumerpage_loyalty_logs_column_rule_type: 'Rule Type',
    consumerpage_loyalty_logs_column_rule_outcome: 'Rule Outcome',
    consumerpage_loyalty_logs_column_event_type: 'Event Type',
    consumerpage_loyalty_logs_column_event_id: 'Event ID',
    consumerpage_loyalty_logs_column_event_name: 'Event Name',
    consumerpage_loyalty_logs_column_corelation_id: 'Correlation ID',

    // Dialogs
    dialog_btn_yes: 'Yes',
    dialog_btn_yes_confirm: 'Yes, I confirm',
    dialog_btn_block: 'Block',
    dialog_btn_no: 'No',
    dialog_btn_cancel: 'Cancel',
    dialog_get_consumer_error_title: 'Error getting consumer data.',
    dialog_get_consumer_error_text: 'Do you want to retry?',
    dialog_password_reset_title: 'Password reset',
    dialog_password_reset_text: 'Do you want to proceed?',
    dialog_consumer_block_title: 'Consumer block',
    dialog_consumer_block_text: 'Additional information required.',
    dialog_consumer_block_reason: 'Blocking Reason',
    dialog_consumer_unblock_title: 'Consumer unblock',
    dialog_consumer_unblock_text: 'Do you really want to proceed?',
    dialog_consumer_delete_title: 'Consumer delete',
    dialog_consumer_delete_text: 'Do you really want to proceed?',
    dialog_unsaved_changes_title: 'Unsaved changes',
    dialog_unsaved_changes_text: 'Do you want to discard the changes?',
    dialog_save_changes_title: 'Save data',
    dialog_save_changes_text: 'Do you want to proceed?',
    dialog_last_name_change_title: 'Consumer name change',
    dialog_last_name_change_text:
        'You are about to change the name of this LAS, do you confirm he/she sent an identity document to prove the change?',
    dialog_set_hav_status_title: 'Setting Hard AV Status',
    dialog_set_hav_status_text: 'Do you want to proceed?',
    dialog_set_hav_status_confirm:
        'You are about to set the HAV status of this LAS, do you confirm their sent an identity document to prove the change?',

    // assign points dialog
    dialog_assign_points_title: 'Assign Points',
    dialog_assign_points_subtitle:
        "Manually assign points to the consumer's account in case of a dispute.",
    dialog_assign_points_input_point_label: 'Number of Points',
    dialog_assign_points_input_remark_label: 'Remark',
    dialog_assign_points_cta_update_points: 'Add points',
    dialog_assign_points_invalid_points: 'Invalid number of points.',

    // Notifications messages
    msg_consumer_updated: 'Consumer updated.',
    msg_consumer_update_failed: 'Consumer update failed.',
    msg_password_reset: 'Password reset successfully.',
    msg_password_reset_failed: 'Password reset failed.',
    msg_password_reset_not_available:
        'Password reset not available for this Consumer.',
    msg_consumer_blocked: 'Consumer blocked.',
    msg_consumer_block_failed: 'Consumer block failed.',
    msg_consumer_unblocked: 'Consumer unblocked.',
    msg_consumer_unblock_failed: 'Consumer unblock failed.',
    msg_consumer_deleted: 'Consumer deleted.',
    msg_consumer_delete_failed: 'Consumer delete failed.',
    msg_hav_status_updated: 'HAV status updated.',
    msg_hav_status_update_failed: 'HAV status update failed.',
    msg_loyalty_add_points_ok: 'Loyalty points added.',
    msg_loyalty_add_points_error: 'Error while adding loyalty points.',
    msg_loyalty_recalc_request_ok: 'Recalculation requested.',
    msg_loyalty_recalc_request_error: 'Error requesting recalculation.',
    msg_brand_data_fetch_error: 'Brand Data fetch error.',
    msg_market_changed: 'Market changed.',
    msg_logged_out: 'Logged out.',
    msg_session_lost: 'User session lost, please log in again.',
    msg_session_expired: 'User session expired, please log in again.',

    // General errors
    err_error_fetching_data: 'Error fetching data.',
    err_refresh: 'Refresh',
    err_retry: 'Retry',

    // Dictionary: yes/no
    dict_yesno: {
        yes: 'Yes',
        no: 'No',
    },

    // Dictionary: gender
    dict_gender: {
        m: 'Male',
        f: 'Female',
        u: 'Unspecified',
    },

    // Dictionary: language
    dict_language: {
        en: 'English',
        fr: 'French',
        de: 'German',
        es: 'Spanish',
        ph: 'Filipino',
        id: 'Indonesian',
        ar: 'Arabic',
        ku: 'Kurdish',
        uk: 'Ukrainian',
    },

    // Dictionary: country
    dict_country: {
        EC: 'Ecuador',
        DE: 'Germany',
        CH: 'Switzerland',
        PH: 'Philippines',
        ID: 'Indonesia',
        PS: 'Palestine',
        IQ: 'Iraq',
        AR: 'Argentina',
        EG: 'Egypt',
        LB: 'Lebanon',
        UA: 'Ukraine',
    },

    // Dictionary: address type
    dict_address_type: {
        personal: 'Personal',
    },

    // Dictionary: HAV status
    dict_hav_status: {
        VERIFIED: 'VERIFIED',
        FAILED: 'FAILED',
        NOT_VERIFIED: 'NOT VERIFIED',
        IN_PROGRESS: 'IN PROGRESS',
        RESET: 'RESET',
    },

    // Dictionary: last updated by
    dict_last_updated_by: {
        CONSUMER: 'CONSUMER',
    },

    // Dictionary: verification status
    dict_verification_status: {
        VERIFIED: 'VERIFIED',
        'NOT VERIFIED': 'NOT VERIFIED',
        INVALID: 'INVALID',
    },

    // Dictionary: blocking reason
    dict_blocking_reason: {
        FRAUDULENT: 'FRAUDULENT',
        DEACTIVATED: 'DEACTIVATED',
        FOR_DELETION: 'FOR DELETION',
        LAS_REQUEST: 'LAS REQUEST',
        NON_SMOKER: 'NON SMOKER',
        EMPLOYEE_TPA: 'EMPLOYEE / TPA',
    },

    // Dictionary: loyalty transaction remark
    dict_loyalty_transaction_remark: {
        POINTS_DELETED: 'POINTS DELETED',
        POINTS_NOT_REFLECTED: 'POINTS NOT REFLECTED',
        FAILURE_OF_REDEMPTION: 'FAILURE OF REDEMPTION',
        OTHER_REASON: 'OTHER REASON',
    },

    // Dictionary: loyalty transaction type
    dict_loyalty_transaction_type: {
        EARN: 'EARN',
        BURN: 'BURN',
    },
};

export default messages;
