// Type utils

export function isString(data) {
    return typeof data === 'string' || data instanceof String;
}

export function asString(data) {
    return isString(data) ? data : data != null ? String(data) : '';
}

export function isFunction(data) {
    return typeof data === 'function' || data instanceof Function;
}

export function normalizeString(data) {
    return asString(data).trim();
}

export function nonEmptyString(data) {
    return isString(data) && data.length > 0;
}

export function toUpperCase(data) {
    return isString(data) ? data.toUpperCase() : data;
}

export function toLowerCase(data) {
    return isString(data) ? data.toLowerCase() : data;
}

export function capitalizeFirstLetter(data) {
    return isString(data) ? data.charAt(0).toUpperCase() + data.slice(1) : data;
}

export function nvl(data, nvl = '') {
    return data != null ? data : nvl;
}
