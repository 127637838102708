// Custom logic PH

import * as DM from '../services/dataModel';
import { FIELDS as F } from '../services/consumerFields';
import { toUpperCase } from '../services/typeUtils';

const HARD_AV_TYPES = ['IDMATCH', 'DATAMATCH'];

export function customFieldPH(model) {
    const avstatus = DM.getValue(model, F.HAV_STATUS);
    const avtype = DM.getValue(model, F.HAV_TYPE);

    const isVerified = avstatus === 'VERIFIED';
    const isHard = HARD_AV_TYPES.includes(toUpperCase(avtype));

    const isHardVerified = isVerified && isHard;
    const editable = !isHardVerified;

    return { visible: true, editable, required: true };
}
