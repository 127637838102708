// German translations

const messages = {
    // Navbar
    navbar_admin_portal: 'Admin Portal',
    navbar_consumer_search: 'Konsumenten Suche',
    navbar_language_choose_language: 'Sprache wählen',
    navbar_market_choose_market: 'Markt wählen',
    navbar_logout: 'Logout',

    // Home page
    homepage_welcome: 'Willkommen im Admin Portal',
    homepage_not_allowed:
        'Sie sind nicht berechtigt, diese Anwendung zu verwenden.',
    homepage_search_btn: 'Konsumenten Suche',
    homepage_login_btn: 'Login',
    homepage_logout_btn: 'Logout',

    // Search page
    searchpage_no_consumers_found: 'Keine Konsument gefunden.',
    searchpage_section_person: 'Person',
    searchpage_section_address: 'Adresse',
    searchpage_btn_search: 'Suche',
    searchpage_btn_clear: 'Löschen',
    searchpage_field_uid: 'Verbraucher-ID',
    searchpage_field_first_name: 'Vorname',
    searchpage_field_last_name: 'Nachname',
    searchpage_field_dob: 'Geburtsdatum ({0})',
    searchpage_field_email: 'Email Adresse',
    searchpage_field_phone: 'Telefonnummer',
    searchpage_field_address: 'Adresse',
    searchpage_field_city: 'Stadt',
    searchpage_field_postal: 'Postleitzahl',
    searchpage_invalid_uid: 'Ungültige Verbraucher-ID.',
    searchpage_invalid_date: 'Ungültiges Datum.',
    searchpage_invalid_email: 'Ungültige Email.',
    searchpage_invalid_phone: 'Ungültige Telefonnummer.',
    searchpage_invalid_wildcard:
        'Ungültige Verwendung von Platzhaltern (*). Einzelplatzhalter bei alleiniger Verwendung zulässig.',
    searchpage_column_first_name: 'Vorname',
    searchpage_column_last_name: 'Nachname',
    searchpage_column_address: 'Adresse',
    searchpage_column_dob: 'Geburtsdatum',
    searchpage_column_email: 'Email',
    searchpage_column_phone: 'Telefonnummer',
    searchpage_column_actions: 'Aktionen',
    searchpage_menu_view_profile: 'Profil anzeigen',
    searchpage_menu_age_verification: 'Altersverifikation',
    searchpage_menu_marketing_prefs: 'Marketingpräferenzen',
    searchpage_menu_password_reset: 'Passwort zurücksetzen',
    searchpage_menu_consumer_delete: 'Konsument löschen',

    // Consumer page: tab names
    consumerpage_tab_system: 'System',
    consumerpage_tab_personal: 'Persönlich',
    consumerpage_tab_address: 'Adressdaten',
    consumerpage_tab_marketing_prefs: 'Marketingpräferenzen',
    consumerpage_tab_age_verification: 'Altersverifikation',
    consumerpage_tab_loyalty_data: 'Loyalty Programs',

    // Consumer page: action buttons
    consumerpage_action_label_disabled: 'Deaktiviert',
    consumerpage_action_label_deleted: 'Gelöscht',
    consumerpage_action_label_locked: 'Gesperrt',
    consumerpage_action_nav_back: 'Zurück zur Suche',
    consumerpage_action_btn_edit: 'Bearbeiten',
    consumerpage_action_btn_save: 'Speichern',
    consumerpage_action_btn_cancel: 'Abbrechen',
    consumerpage_action_btn_reset_password: 'Passwort zurücksetzen',
    consumerpage_action_btn_block_consumer: 'Block Consumer',
    consumerpage_action_btn_unblock_consumer: 'Unblock Consumer',
    consumerpage_action_btn_delete_consumer: 'Konsument löschen',
    consumerpage_action_btn_set_hav_status: 'HAV-Status festlegen',
    consumerpage_action_btn_assign_points: 'Assign Points',

    // Consumer page: validation errors
    consumerpage_error_rejected_by_server: 'Wert vom Server abgelehnt.',
    consumerpage_error_required: 'Feld ist erforderlich.',
    consumerpage_error_too_long: 'Wert zu lang.',
    consumerpage_error_date: 'Ungültiges Datum.',
    consumerpage_error_date_in_future: 'Datum in der Zukunft.',
    consumerpage_error_age_under_18: 'Hinweis: Alter unter 18 Jahren.',
    consumerpage_error_email: 'Ungültige Email.',
    consumerpage_error_phone: 'Ungültige Telefonnummer.',
    consumerpage_error_country: 'Ungültiges Land.',
    consumerpage_error_province: 'Ungültige Provinz.',
    consumerpage_error_city: 'Ungültige Stadt.',
    consumerpage_error_postal: 'Ungültige Postleitzahl.',

    // Consumer page: System tab
    consumerpage_system_section_blockings: 'Blockaden',
    consumerpage_system_section_blockings_hist_data: 'Historische Daten',
    consumerpage_system_field_consumer_id: 'Verbraucher-ID',
    consumerpage_system_field_created: 'Erstellt',
    consumerpage_system_field_registered: 'Registriert',
    consumerpage_system_field_registration_time: 'Registrierungszeit',
    consumerpage_system_field_registration_brand: 'Registrierungsmarke',
    consumerpage_system_field_registration_channel: 'Registrierungskanal',
    consumerpage_system_field_touchpoint_type: 'Touchpoint-Typ',
    consumerpage_system_field_touchpoint_id: 'Touchpoint-ID',
    consumerpage_system_field_account_locked: 'Konto gesperrt',
    consumerpage_system_field_login_allowed: 'Anmelden erlaubt',
    consumerpage_system_field_last_login: 'Letzte Anmeldung',
    consumerpage_system_field_last_updated: 'Zuletzt aktualisiert',
    consumerpage_system_field_last_updated_by: 'Zuletzt aktualisiert von',
    consumerpage_system_field_blockedBy: 'Blockiert von',
    consumerpage_system_field_blockedReason: 'Blockiert Grund',
    consumerpage_system_field_blockedDate: 'Blockiertes Datum',

    // Consumer page: Personal tab
    consumerpage_personal_field_first_name: 'Vorname',
    consumerpage_personal_field_last_name: 'Nachname',
    consumerpage_personal_field_full_name: 'Name',
    consumerpage_personal_field_dob: 'Geburtsdatum',
    consumerpage_personal_field_gender: 'Geschlecht',
    consumerpage_personal_field_email: 'Email',
    consumerpage_personal_field_language: 'Sprache',
    consumerpage_personal_field_home_country: 'Land',
    consumerpage_personal_field_mobile_phone: 'Mobilnummer',
    consumerpage_personal_field_home_phone: 'Festnetznummer',
    consumerpage_personal_field_mobile_verification_status:
        'Verifizierungsstatus der Mobilnummer',
    consumerpage_personal_field_mobile_verification_date:
        'Bestätigungsdatum der Mobilnummer',

    // Consumer page: Address tab
    consumerpage_address_field_addr_type: 'Adresstyp',
    consumerpage_address_field_country: 'Land',
    consumerpage_address_field_province: 'Provinz',
    consumerpage_address_field_city: 'Stadt',
    consumerpage_address_field_addr_line_1: 'Adress Zeile 1',
    consumerpage_address_field_addr_line_2: 'Adress Zeile 2',
    consumerpage_address_field_postal: 'Postleitzahl',

    // Consumer page: Marketing Prefs tab
    consumerpage_marketing_section_channel: 'Channel Opt-ins',
    consumerpage_marketing_section_brand: 'Brand Opt-ins',
    consumerpage_marketing_section_brand_seg: 'Markensegmentierung',
    consumerpage_marketing_section_brand_pref: 'Markenpräferenzen',
    consumerpage_marketing_field_opt_email: 'E-Mail empfangen',
    consumerpage_marketing_field_opt_sms: 'SMS empfangen',
    consumerpage_marketing_field_opt_calls: 'Telefonanrufe erhalten',
    consumerpage_marketing_field_opt_dm: 'Post empfangen',
    consumerpage_marketing_field_opt_brand: 'Markenkommunikation erhalten',
    consumerpage_marketing_field_brand: 'Marke',
    consumerpage_marketing_field_subbrand: 'Untermarke',
    consumerpage_marketing_msg_choose_brand: 'Wählen Sie Marke',
    consumerpage_marketing_msg_choose_subbrand: 'Wählen Sie Untermarke',
    consumerpage_marketing_msg_invalid_brand: '<Ungültiger Marke>',
    consumerpage_marketing_msg_invalid_subbrand: '<Ungültiger Untermarke>',

    // Consumer page: Age Verification tab
    consumerpage_age_section_label_hav: 'Altersverifikation (HAV)',
    consumerpage_age_field_dob: 'Geburtsdatum',
    consumerpage_age_field_home_country: 'Land',
    consumerpage_age_field_hav_last_updated: 'Zuletzt aktualisiert',
    consumerpage_age_field_hav_country: 'Land',
    consumerpage_age_field_hav_type: 'Typ',
    consumerpage_age_field_hav_status: 'Status',

    // Consumer page: Loyalty Data tab
    consumerpage_loyalty_msg_consumer_not_registered:
        'Consumer is not enrolled in loyalty program.',

    consumerpage_loyalty_field_current_points: 'Gesamtaktuelle Punkte',
    consumerpage_loyalty_field_current_tier: 'Aktuelle Stufe',
    consumerpage_loyalty_field_next_tier: 'Kommende Stufe',
    consumerpage_loyalty_field_next_tier_points: 'Punkte für nächste Stufe',
    consumerpage_loyalty_field_brand: 'Marke auswählen',

    consumerpage_loyalty_label_needs_recalc: 'NEEDS RECALCULATION',

    consumerpage_loyalty_btn_recalc: 'Recalculate',
    consumerpage_loyalty_btn_recalc_requested: 'Requested',

    consumerpage_loyalty_tab_transactions: 'Transaktionshistorie',
    consumerpage_loyalty_tab_auditlogs: 'Audit Log',

    consumerpage_loyalty_transaction_column_time: 'Transaction Time',
    consumerpage_loyalty_transaction_column_type: 'Type',
    consumerpage_loyalty_transaction_column_details: 'Details',
    consumerpage_loyalty_transaction_column_points: 'Points',
    consumerpage_loyalty_transaction_column_remarks: 'Remarks',
    consumerpage_loyalty_transaction_column_created_by: 'Created By',

    consumerpage_loyalty_logs_column_time: 'Log Time',
    consumerpage_loyalty_logs_column_rule_status: 'Rule Status',
    consumerpage_loyalty_logs_column_rule_type: 'Rule Type',
    consumerpage_loyalty_logs_column_rule_outcome: 'Rule Outcome',
    consumerpage_loyalty_logs_column_event_type: 'Event Type',
    consumerpage_loyalty_logs_column_event_id: 'Event ID',
    consumerpage_loyalty_logs_column_event_name: 'Event Name',
    consumerpage_loyalty_logs_column_corelation_id: 'Correlation ID',

    // Dialogs
    dialog_btn_yes: 'Ja',
    dialog_btn_yes_confirm: 'Ja, ich bestätige',
    dialog_btn_block: 'Block',
    dialog_btn_no: 'Nein',
    dialog_btn_cancel: 'Abbrechen',
    dialog_get_consumer_error_title:
        'Fehler beim Abrufen der Konsumentendaten.',
    dialog_get_consumer_error_text: 'Möchten Sie es erneut versuchen?',
    dialog_password_reset_title: 'Passwort zurücksetzen',
    dialog_password_reset_text: 'Möchten Sie fortfahren?',
    dialog_consumer_block_title: 'Consumer block',
    dialog_consumer_block_text: 'Zusätzliche Informationen erforderlich.',
    dialog_consumer_block_reason: 'Blockiert Grund',
    dialog_consumer_unblock_title: 'Consumer unblock',
    dialog_consumer_unblock_text: 'Möchten Sie wirklich fortfahren?',
    dialog_consumer_delete_title: 'Konsument löschen',
    dialog_consumer_delete_text: 'Möchten Sie wirklich fortfahren?',
    dialog_unsaved_changes_title: 'Nicht gespeicherte Änderungen',
    dialog_unsaved_changes_text: 'Möchten Sie die Änderungen verwerfen?',
    dialog_save_changes_title: 'Daten speichern',
    dialog_save_changes_text: 'Möchten Sie fortfahren?',
    dialog_last_name_change_title: 'Änderung des Nachnamens',
    dialog_last_name_change_text:
        'Sie sind im Begriff, den Namen dieses Konsumenten zu ändern. Bestätigen Sie, dass er/sie ein Ausweisdokument gesendet hat, um die Änderung zu beweisen?',
    dialog_set_hav_status_title: 'Einstellen des HAV-Status',
    dialog_set_hav_status_text: 'Möchten Sie fortfahren?',
    dialog_set_hav_status_confirm:
        'Sie sind im Begriff, den HAV-Status dieses Konsumenten zu setzen. Bestätigen Sie, dass er/sie ein Ausweisdokument gesendet hat, um die Änderung zu beweisen?',

    // assign points dialog
    dialog_assign_points_title: 'Punkte manuell zuweisen',
    dialog_assign_points_subtitle:
        'Punkte manuell dem Verbraucherkonto zuweisen im Falle eines Streits.',
    dialog_assign_points_input_point_label: 'Anzahl der Punkte eingeben',
    dialog_assign_points_input_remark_label: 'Bemerkung',
    dialog_assign_points_cta_update_points: 'Punkte aktualisieren',
    dialog_assign_points_invalid_points: 'Invalid number of points.',

    // Notifications messages
    msg_consumer_updated: 'Konsument aktualisiert.',
    msg_consumer_update_failed: 'Konsumentenaktualisierung fehlgeschlagen.',
    msg_password_reset: 'Passwort erfolgreich zurückgesetzt.',
    msg_password_reset_failed: 'Passwort zurücksetzen fehlgeschlagen.',
    msg_password_reset_not_available:
        'Das Zurücksetzen des Passworts ist für diesen Verbraucher nicht verfügbar.',
    msg_consumer_blocked: 'Consumer blocked.',
    msg_consumer_block_failed: 'Consumer block failed.',
    msg_consumer_unblocked: 'Consumer unblocked.',
    msg_consumer_unblock_failed: 'Consumer unblock failed.',
    msg_consumer_deleted: 'Konsument gelöscht.',
    msg_consumer_delete_failed: 'Verbraucherlöschung fehlgeschlagen.',
    msg_hav_status_updated: 'HAV-Status aktualisiert.',
    msg_hav_status_update_failed: 'HAV-Statusaktualisierung fehlgeschlagen.',
    msg_loyalty_add_points_ok: 'Loyalty points added.',
    msg_loyalty_add_points_error: 'Error while adding loyalty points.',
    msg_loyalty_recalc_request_ok: 'Recalculation requested.',
    msg_loyalty_recalc_request_error: 'Error requesting recalculation.',
    msg_brand_data_fetch_error: 'Fehler beim Abrufen von Markendaten.',
    msg_market_changed: 'Markt geändert.',
    msg_logged_out: 'Abgemeldet.',
    msg_session_lost:
        'Benutzersitzung verloren, bitte melden Sie sich erneut an.',
    msg_session_expired:
        'Benutzersitzung abgelaufen, bitte melden Sie sich erneut an.',

    // General errors
    err_error_fetching_data: 'Error fetching data.',
    err_refresh: 'Refresh',
    err_retry: 'Retry',

    // Dictionary: yes/no
    dict_yesno: {
        yes: 'Ja',
        no: 'Nein',
    },

    // Dictionary: gender
    dict_gender: {
        m: 'Männlich',
        f: 'Weiblich',
        u: 'Nicht spezifiziert',
    },

    // Dictionary: language
    dict_language: {
        en: 'Englisch',
        fr: 'Französisch',
        de: 'Deutsch',
        es: 'Spanisch',
        ph: 'Philippinisch',
        id: 'Indonesisch',
        ar: 'Arabisch',
        ku: 'Kurdisch',
        uk: 'Ukrainisch',
    },

    // Dictionary: country
    dict_country: {
        EC: 'Ecuador',
        DE: 'Deutschland',
        CH: 'Schweiz',
        PH: 'Philippinen',
        ID: 'Indonesien',
        PS: 'Palästina',
        IQ: 'Irak',
        AR: 'Argentinien',
        EG: 'Ägypten',
        LB: 'Libanon',
        UA: 'Ukraine',
    },

    // Dictionary: address type
    dict_address_type: {
        personal: 'Persönlich',
    },

    // Dictionary: HAV status
    dict_hav_status: {
        VERIFIED: 'VERIFIZIERT (VERIFIED)',
        FAILED: 'GESCHEITERT (FAILED)',
        NOT_VERIFIED: 'NICHT VERIFIZIERT (NOT_VERIFIED)',
        IN_PROGRESS: 'IN BEARBEITUNG (IN_PROGRESS)',
        RESET: 'ZURÜCKSETZEN (RESET)',
    },

    // Dictionary: last updated by
    dict_last_updated_by: {
        CONSUMER: 'KONSUMENT',
    },

    // Dictionary: verification status
    dict_verification_status: {
        VERIFIED: 'VERIFIZIERT',
        'NOT VERIFIED': 'NICHT VERIFIZIERT',
        INVALID: 'UNGÜLTIG',
    },

    // Dictionary: blocking reason
    dict_blocking_reason: {
        FRAUDULENT: 'FRAUDULENT',
        DEACTIVATED: 'DEACTIVATED',
        FOR_DELETION: 'FOR DELETION',
        LAS_REQUEST: 'LAS REQUEST',
        NON_SMOKER: 'NON SMOKER',
        EMPLOYEE_TPA: 'EMPLOYEE / TPA',
    },

    // Dictionary: loyalty transaction remark
    dict_loyalty_transaction_remark: {
        POINTS_DELETED: 'POINTS DELETED',
        POINTS_NOT_REFLECTED: 'POINTS NOT REFLECTED',
        FAILURE_OF_REDEMPTION: 'FAILURE OF REDEMPTION',
        OTHER_REASON: 'OTHER REASON',
    },

    // Dictionary: loyalty transaction type
    dict_loyalty_transaction_type: {
        EARN: 'EARN',
        BURN: 'BURN',
    },
};

export default messages;
