import { useCallback } from 'react';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import * as DM from '../../../services/dataModel';
import { ItemValue } from '../../../services/ItemValue';
import { nvl } from '../../../services/typeUtils';
import { normalizeArray } from '../../../services/objectUtils';
import { useMessages } from '../../../hooks/useMessages';

const LabeledValue = ({ value, labels }) => {
    const labelsN = normalizeArray(labels).filter(
        (item) => item.label != null || item.separator || item.component
    );
    return (
        <Stack direction="row" alignItems="center" gap={1} flexWrap="wrap">
            {value}
            {labelsN.map((item, index) => {
                if (item.separator) {
                    return <Box key={index} sx={{ flex: 1 }} />;
                }
                if (item.component) {
                    const Component = item.component;
                    return <Component key={index} />;
                }
                return (
                    <Chip
                        key={index}
                        size="small"
                        color={item.color || 'default'}
                        label={item.label}
                        sx={{ fontSize: '0.7rem', fontWeight: 'normal' }}
                    />
                );
            })}
        </Stack>
    );
};

const FieldValue = ({ model, item, onChange }) => {
    const { editMode } = model;
    const { isText, isDate, isCheckbox, isDropdown } = item || {};
    const { field, editable, values, valuesMap, getLabels } = item || {};

    const props = DM.getDisplayProps(model, item);
    const { value, valueFmt, error, hint, isChanged } = props;

    const { getMessage } = useMessages();

    const helperText = nvl(getMessage(error) || getMessage(hint));

    const canEdit = editable && editMode && onChange != null;

    const valueDropdown =
        isDropdown && canEdit && valuesMap[value] ? value : '';

    const labels = getLabels(model);

    const handleChange = useCallback(
        (e) => {
            if (canEdit) {
                const { value, checked } = e.target;
                const newValue = item.isCheckbox ? checked : value;
                onChange(new ItemValue(item, newValue));
            }
        },
        [canEdit, onChange, item]
    );

    const handleBlur = useCallback(
        (e) => {
            if (canEdit && isChanged && !error) {
                const { value, checked } = e.target;
                const newValue = item.isCheckbox ? checked : value;
                const reformatted = nvl(item.reformat(newValue));
                if (newValue !== reformatted) {
                    onChange(new ItemValue(item, reformatted));
                }
            }
        },
        [canEdit, onChange, item, isChanged, error]
    );

    if (!item?.visible) return null;

    return (
        <>
            {isText && !canEdit && (
                <LabeledValue value={valueFmt} labels={labels} />
            )}
            {isText && canEdit && (
                <TextField
                    autoComplete="off"
                    fullWidth
                    name={field}
                    variant="standard"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={valueFmt}
                    error={!!error}
                    helperText={helperText}
                />
            )}
            {isDate && !canEdit && (
                <LabeledValue value={valueFmt} labels={labels} />
            )}
            {isDate && canEdit && (
                <TextField
                    autoComplete="off"
                    name={field}
                    variant="standard"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={valueFmt}
                    error={!!error}
                    helperText={helperText}
                />
            )}
            {isCheckbox && (
                <Checkbox
                    name={field}
                    size="small"
                    disabled={!editMode}
                    checked={!!valueFmt}
                    onChange={handleChange}
                />
            )}
            {isDropdown && !canEdit && valueFmt}
            {isDropdown && canEdit && (
                <TextField
                    select
                    name={field}
                    variant="standard"
                    onChange={handleChange}
                    value={valueDropdown}
                    error={!!error}
                    helperText={helperText}
                >
                    {values.map((item, index) => (
                        <MenuItem key={index} value={item.value}>
                            {item.label}
                        </MenuItem>
                    ))}
                </TextField>
            )}
        </>
    );
};

export default FieldValue;
