// Consumer Profile actions

import * as types from './actionTypes';
import * as consumerApi from '../../services/consumerApi';
import { getConsumerUpdatePayload } from '../../services/consumerApiRequest';
import { deepClone } from '../../services/objectUtils';
import { searchResultUpdate } from './searchActions';
import { dialogClose, dialogLoading } from './dialogActions';
import { notifyError, notifySuccess } from './notificationActions';

function getMarket(state) {
    return state?.auth?.user?.market;
}

export function profileData(data) {
    return function (dispatch, getState) {
        const country = getMarket(getState());
        dispatch(searchResultUpdate(deepClone(data)));
        dispatch({
            type: types.PROFILE_DATA,
            data: consumerApi.normalizeConsumerGet(data, country),
        });
    };
}

export function profileEditMode(editMode) {
    return { type: types.PROFILE_EDIT_MODE, editMode };
}

export function profileValidateRequired(sections) {
    return { type: types.PROFILE_VALIDATE_REQUIRED, sections };
}

export function profileChange(itemValue) {
    return { type: types.PROFILE_CHANGE, itemValue };
}

export function profileChangeClear() {
    return { type: types.PROFILE_CHANGE_CLEAR };
}

export function profileGetError(error) {
    return { type: types.PROFILE_GET_ERROR, error };
}

export function profileUpdateError(error) {
    return { type: types.PROFILE_UPD_ERROR, error };
}

export function profileStepError(step, error) {
    if (step === 1) {
        return profileUpdateError(error);
    } else {
        return profileGetError(error);
    }
}

export function executeConsumerGet(consumerId) {
    return function (dispatch, getState) {
        const country = getMarket(getState());
        return Promise.resolve()
            .then(() => consumerApi.consumerGet(country, consumerId))
            .then((result) => dispatch(profileData(result.data)))
            .catch((error) => dispatch(profileGetError(error)));
    };
}

export function executeConsumerUpdate(consumerId, model) {
    return function (dispatch, getState) {
        const country = getMarket(getState());
        let step = 1;
        return Promise.resolve()
            .then(() => dispatch(dialogLoading()))
            .then(() => getConsumerUpdatePayload(model, country))
            .then((params) =>
                consumerApi.consumerUpdate(country, consumerId, params)
            )
            .then(() => (step = 2))
            .then(() => consumerApi.consumerGet(country, consumerId))
            .then((result) => dispatch(profileData(result.data)))
            .then(() => {
                dispatch(dialogClose());
                dispatch(notifySuccess((L) => L.msg_consumer_updated));
            })
            .catch((error) => {
                dispatch(dialogClose());
                dispatch(notifyError((L) => L.msg_consumer_update_failed));
                dispatch(profileStepError(step, error));
            });
    };
}

export function executeConsumerBlock(consumerId, reason) {
    return function (dispatch, getState) {
        const country = getMarket(getState());
        let step = 1;
        return Promise.resolve()
            .then(() => dispatch(dialogLoading()))
            .then(() => consumerApi.consumerBlock(country, consumerId, reason))
            .then(() => (step = 2))
            .then(() => consumerApi.consumerGet(country, consumerId))
            .then((result) => dispatch(profileData(result.data)))
            .then(() => {
                dispatch(dialogClose());
                dispatch(notifySuccess((L) => L.msg_consumer_blocked));
            })
            .catch((error) => {
                dispatch(dialogClose());
                dispatch(notifyError((L) => L.msg_consumer_block_failed));
                dispatch(profileStepError(step, error));
            });
    };
}

export function executeConsumerUnblock(consumerId) {
    return function (dispatch, getState) {
        const country = getMarket(getState());
        let step = 1;
        return Promise.resolve()
            .then(() => dispatch(dialogLoading()))
            .then(() => consumerApi.consumerUnblock(country, consumerId))
            .then(() => (step = 2))
            .then(() => consumerApi.consumerGet(country, consumerId))
            .then((result) => dispatch(profileData(result.data)))
            .then(() => {
                dispatch(dialogClose());
                dispatch(notifySuccess((L) => L.msg_consumer_unblocked));
            })
            .catch((error) => {
                dispatch(dialogClose());
                dispatch(notifyError((L) => L.msg_consumer_unblock_failed));
                dispatch(profileStepError(step, error));
            });
    };
}

export function executeConsumerDelete(consumerId) {
    return function (dispatch, getState) {
        const country = getMarket(getState());
        let step = 1;
        return Promise.resolve()
            .then(() => dispatch(dialogLoading()))
            .then(() => consumerApi.consumerDelete(country, consumerId))
            .then(() => (step = 2))
            .then(() => consumerApi.consumerGet(country, consumerId))
            .then((result) => dispatch(profileData(result.data)))
            .then(() => {
                dispatch(dialogClose());
                dispatch(notifySuccess((L) => L.msg_consumer_deleted));
            })
            .catch((error) => {
                dispatch(dialogClose());
                dispatch(notifyError((L) => L.msg_consumer_delete_failed));
                dispatch(profileStepError(step, error));
            });
    };
}

export function executeResetPassword(params) {
    return function (dispatch, getState) {
        const country = getMarket(getState());
        return Promise.resolve()
            .then(() => {
                if (!params) throw new Error();
            })
            .then(() => dispatch(dialogLoading()))
            .then(() => consumerApi.resetPassword(country, params))
            .then(() => {
                dispatch(dialogClose());
                dispatch(notifySuccess((L) => L.msg_password_reset));
            })
            .catch(() => {
                dispatch(dialogClose());
                const msg = params
                    ? (L) => L.msg_password_reset_failed
                    : (L) => L.msg_password_reset_not_available;
                dispatch(notifyError(msg));
            });
    };
}

export function executeAvSet(consumerId) {
    return function (dispatch, getState) {
        const country = getMarket(getState());
        let step = 1;
        return Promise.resolve()
            .then(() => dispatch(dialogLoading()))
            .then(() => consumerApi.avSet(country, consumerId))
            .then(() => (step = 2))
            .then(() => consumerApi.consumerGet(country, consumerId))
            .then((result) => dispatch(profileData(result.data)))
            .then(() => {
                dispatch(dialogClose());
                dispatch(notifySuccess((L) => L.msg_hav_status_updated));
            })
            .catch((error) => {
                dispatch(dialogClose());
                dispatch(notifyError((L) => L.msg_hav_status_update_failed));
                dispatch(profileStepError(step, error));
            });
    };
}
