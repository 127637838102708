import { useDispatch, useSelector } from 'react-redux';
import { Button, CircularProgress } from '@mui/material';

import { useMessages } from '../../../../hooks/useMessages';
import { FIELDS as F } from '../../../../services/consumerFields';
import * as DM from '../../../../services/dataModel';
import { loyaltyRecalcTier } from '../../../../store/actions/loyaltyActions';

const RecalculateTier = () => {
    const dispatch = useDispatch();
    const { L } = useMessages();

    const model = useSelector((state) => state.profile);

    const { user } = useSelector((state) => state.auth);
    const { hasConsumerUpdate } = user || {};

    const { brand, recalcData } = useSelector((state) => state.loyalty);
    const { loading, requested } = recalcData || {};

    const UID = DM.getValue(model, F.UID);
    const isActive = DM.getValue(model, F.IS_ACTIVE_EX);
    const isDeleted = DM.getValue(model, F.IS_DELETED);

    const canRecalc =
        hasConsumerUpdate && isActive && !isDeleted && !loading && !requested;

    const handleClick = () => {
        dispatch(loyaltyRecalcTier(UID, brand));
    };

    const label = requested
        ? L.consumerpage_loyalty_btn_recalc_requested
        : L.consumerpage_loyalty_btn_recalc;

    return (
        <>
            {(loading && <CircularProgress size="1.25rem" />) || (
                <Button
                    variant="text"
                    color="primary"
                    size="small"
                    disabled={!canRecalc}
                    onClick={handleClick}
                    sx={{ marginY: '-8px' }}
                >
                    {label}
                </Button>
            )}
        </>
    );
};

export default RecalculateTier;
