// Consumer Search actions

import * as types from './actionTypes';
import * as consumerApi from '../../services/consumerApi';
import * as C from '../../config/marketConfig';

export function searchChange(changeType, itemValue) {
    return { type: types.SEARCH_CHANGE, changeType, itemValue };
}

export function searchStart() {
    return { type: types.SEARCH_START };
}

export function searchResult(result) {
    return {
        type: types.SEARCH_RESULT,
        result: consumerApi.normalizeConsumerSearch(result),
    };
}

export function searchResultUpdate(item) {
    return {
        type: types.SEARCH_RESULT_UPDATE,
        item: consumerApi.normalizeConsumerSearchItem(item),
    };
}

export function searchError(error) {
    return { type: types.SEARCH_ERROR, error };
}

export function searchClear() {
    return { type: types.SEARCH_CLEAR };
}

export function setPage(page) {
    return { type: types.SEARCH_SET_PAGE, page };
}

export function setRowsPerPage(rowsPerPage) {
    return { type: types.SEARCH_SET_ROWS_PER_PAGE, rowsPerPage };
}

export function setOrder(order) {
    return { type: types.SEARCH_SET_ORDER, order };
}

export function setOrderBy(orderBy) {
    return { type: types.SEARCH_SET_ORDER_BY, orderBy };
}

export function executeSearch(params) {
    return function (dispatch, getState) {
        const { auth } = getState();
        const country = auth.user && auth.user.market;
        const limit = C.getMarketValue(C.MARKET_SEARCH_LIMIT, country);
        return Promise.resolve()
            .then(() => dispatch(searchStart()))
            .then(() => {
                const searchParams = Object.fromEntries(
                    Object.entries({
                        UID: params.UID,
                        firstName: params.firstName,
                        lastName: params.lastName,
                        dob: params.dob,
                        email: params.email,
                        phoneNumber: params.phoneNumber,
                        phoneType: params.phoneNumber ? 'all' : '',
                        addressLine1: params.addressLine1,
                        city: params.city,
                        postal: params.postal,
                        size: String(limit || ''),
                    }).filter(([_, v]) => !!v)
                );
                return consumerApi.consumerSearch(country, searchParams);
            })
            .then((result) => dispatch(searchResult(result.data)))
            .catch((error) => dispatch(searchError(error)));
    };
}
