import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    Box,
    CircularProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';

import * as DM from '../../../services/dataModel';
import { FIELDS as F } from '../../../services/consumerFields';
import { useMessages } from '../../../hooks/useMessages';
import { executeGetBrands } from '../../../store/actions/brandActions';
import AsyncDropdown from '../../common/AsyncDropdown';
import { profileChange } from '../../../store/actions/profileActions';
import { ItemValue } from '../../../services/ItemValue';
import { getFields } from './fields/fieldsBrandSegmentation';

const cellHeader = {
    backgroundColor: (theme) => theme.palette.tabHeader.main,
    fontWeight: 'bold',
};

const contentCell = {
    width: '50%',
    fontWeight: 'bold',
};

const rowStyle = (changed) => ({
    cursor: 'pointer',
    backgroundColor: (theme) =>
        changed ? theme.palette.changed.main : theme.palette.background.paper,
    '&:hover': {
        backgroundColor: (theme) =>
            changed
                ? theme.palette.changed.main
                : theme.palette.hoverBackground.main,
    },
});

const V = (value) => (value instanceof ItemValue ? value.value : value);

const isEqual = (option, value) => option === value;

const BRAND_FIELDS = [F.BRAND_FAMILY, F.BRAND_FAMILY_ID];
const SUB_BRAND_FIELDS = [F.BRAND_SUB_FAMILY, F.BRAND_SUB_FAMILY_ID];

const BrandSegmentation = ({ model }) => {
    const { editMode } = model;

    const dispatch = useDispatch();
    const { L } = useMessages();

    const { user } = useSelector((state) => state.auth);
    const { market } = user || {};

    const { isLoading, brands, brandsMap } = useSelector(
        (state) => state.brand
    );

    const {
        sectionLabel,
        itemBrand,
        itemBrandId,
        itemSubBrand,
        itemSubBrandId,
    } = useMemo(() => getFields(L, market), [L, market]);

    const { visible: brandVisible, editable: brandEditable } = itemBrand;
    const { visible: subBrandVisible, editable: subBrandEditable } =
        itemSubBrand;

    const visible = brandVisible || subBrandVisible;

    useEffect(
        () => visible && dispatch(executeGetBrands()),
        [visible, dispatch]
    );

    if (!visible) return null;

    const brandEditMode = editMode && brandEditable;
    const subBrandEditMode = editMode && subBrandEditable;

    const brandKey = V(DM.getCurrentValue(model, F.BRAND_FAMILY));
    const subBrandKey = V(DM.getCurrentValue(model, F.BRAND_SUB_FAMILY));

    const brandError = DM.getError(model, F.BRAND_FAMILY);
    const subBrandError = DM.getError(model, F.BRAND_SUB_FAMILY);

    const subBrands = brandsMap?.[brandKey]?.subBrands || [];
    const subBrandsMap = brandsMap?.[brandKey]?.subBrandsMap || {};

    const brandOptions = brands?.map((item) => item.desc);
    const subBrandOptions = subBrands?.map((item) => item.desc);

    const getBrand = (key) => brandsMap?.[key];
    const getSubBrand = (key) => subBrandsMap?.[key];

    const getBrandLabel = (key) => getBrand(key)?.market || '';
    const getSubBrandLabel = (key) => getSubBrand(key)?.market || '';

    const onChangeBrand = (_, value) => {
        if (value === brandKey) return;

        const valueId = getBrand(value)?.id || '';
        const valueDesc = value || '';

        if (valueId && valueDesc) {
            dispatch(profileChange(new ItemValue(itemBrandId, valueId)));
            dispatch(profileChange(new ItemValue(itemBrand, valueDesc)));
        } else {
            dispatch(profileChange(new ItemValue(itemBrandId, '')));
            dispatch(profileChange(new ItemValue(itemBrand, '')));
        }

        dispatch(profileChange(new ItemValue(itemSubBrandId, '')));
        dispatch(profileChange(new ItemValue(itemSubBrand, '')));
    };

    const onChangeSubBrand = (_, value) => {
        if (value === subBrandKey) return;

        const valueId = getSubBrand(value)?.id || '';
        const valueDesc = value || '';

        if (valueId && valueDesc) {
            dispatch(profileChange(new ItemValue(itemSubBrandId, valueId)));
            dispatch(profileChange(new ItemValue(itemSubBrand, valueDesc)));
        } else {
            dispatch(profileChange(new ItemValue(itemSubBrandId, '')));
            dispatch(profileChange(new ItemValue(itemSubBrand, '')));
        }
    };

    const brandLabel = getBrandLabel(brandKey);
    const subBrandLabel = getSubBrandLabel(subBrandKey);

    const invalidBrand = brandKey && !brandLabel;
    const invalidSubBrand = subBrandKey && !subBrandLabel;

    const brandDisplay = invalidBrand
        ? L.consumerpage_marketing_msg_invalid_brand
        : brandLabel;
    const subBrandDisplay = invalidSubBrand
        ? L.consumerpage_marketing_msg_invalid_subbrand
        : subBrandLabel;

    const brandOption = invalidBrand ? null : brandKey;
    const subBrandOption = invalidSubBrand ? null : subBrandKey;

    const brandChanged = DM.changedAny(model, BRAND_FIELDS);
    const subBrandChanged = DM.changedAny(model, SUB_BRAND_FIELDS);

    return (
        <Box
            sx={{
                mb: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={2} sx={cellHeader}>
                                {sectionLabel}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {brandVisible && (
                            <TableRow sx={rowStyle(brandChanged)}>
                                <TableCell style={contentCell}>
                                    {itemBrand.label}
                                </TableCell>
                                <TableCell style={contentCell}>
                                    {isLoading && (
                                        <CircularProgress size={16} />
                                    )}
                                    {!isLoading &&
                                        !brandEditMode &&
                                        brandDisplay}
                                    {!isLoading && brandEditMode && (
                                        <AsyncDropdown
                                            label={
                                                L.consumerpage_marketing_msg_choose_brand
                                            }
                                            options={brandOptions}
                                            value={brandOption || null}
                                            isOptionEqualToValue={isEqual}
                                            getOptionLabel={getBrandLabel}
                                            onChange={onChangeBrand}
                                            error={brandError}
                                        />
                                    )}
                                </TableCell>
                            </TableRow>
                        )}
                        {subBrandVisible && (
                            <TableRow sx={rowStyle(subBrandChanged)}>
                                <TableCell style={contentCell}>
                                    {itemSubBrand.label}
                                </TableCell>
                                <TableCell style={contentCell}>
                                    {isLoading && (
                                        <CircularProgress size={16} />
                                    )}
                                    {!isLoading &&
                                        !subBrandEditMode &&
                                        subBrandDisplay}
                                    {!isLoading && subBrandEditMode && (
                                        <AsyncDropdown
                                            disabled={!brandOption}
                                            label={
                                                L.consumerpage_marketing_msg_choose_subbrand
                                            }
                                            options={subBrandOptions}
                                            value={subBrandOption || null}
                                            isOptionEqualToValue={isEqual}
                                            getOptionLabel={getSubBrandLabel}
                                            onChange={onChangeSubBrand}
                                            error={subBrandError}
                                        />
                                    )}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default BrandSegmentation;
