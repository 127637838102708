import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from '@mui/material';

import FieldValue from './FieldValue';

const headerStyle = {
    backgroundColor: (theme) => theme.palette.tabHeader.main,
};

const rowStyle = {
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: (theme) => theme.palette.hoverBackground.main,
    },
};

const TableSection = ({ section, model, pagination, onPageChange }) => {
    const { items } = section;
    const { data = [], count = 0 } = model || {};
    const { page = 0, rowsPerPage = 10 } = pagination || {};

    const handleChangePage = (_, page) => {
        onPageChange(page, rowsPerPage);
    };

    const handleChangeRowsPerPage = (event) => {
        onPageChange(0, +event.target.value);
    };

    return (
        <TableContainer>
            <Table>
                <TableHead sx={headerStyle}>
                    <TableRow>
                        {items.map((item, index) => (
                            <TableCell key={index}>{item.label}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row, rowKey) => (
                        <TableRow key={rowKey} sx={rowStyle}>
                            {items.map((item, itemKey) => (
                                <TableCell
                                    key={itemKey}
                                    sx={{
                                        overflowWrap: item?.wrap
                                            ? 'anywhere'
                                            : 'normal',
                                    }}
                                >
                                    <FieldValue
                                        item={item}
                                        model={{ data: row }}
                                    />
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <TablePagination
                component="div"
                page={page}
                count={count}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[10, 25, 50]}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </TableContainer>
    );
};

export default TableSection;
