import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Snackbar, Alert, Typography } from '@mui/material';
import { useMessages } from '../../hooks/useMessages';
import { notificationClose } from '../../store/actions/notificationActions';

const DURATION = {
    DEFAULT: 5000,
    success: 4000,
};

const Notification = () => {
    const dispatch = useDispatch();
    const { getMessage } = useMessages();

    const notification = useSelector((state) => state.notification);
    const { isOpen, severity, message } = notification;

    const duration = DURATION[severity] || DURATION.DEFAULT;
    const msg = getMessage(message);

    const handleClose = () => {
        dispatch(notificationClose());
    };

    return (
        <Snackbar
            open={isOpen}
            autoHideDuration={duration}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            onClose={handleClose}
        >
            <Alert
                severity={severity || 'success'}
                onClose={handleClose}
                sx={{ py: 2 }}
            >
                <Typography sx={{ pr: 1 }}>{msg}</Typography>
            </Alert>
        </Snackbar>
    );
};

export default Notification;
