import * as types from './actionTypes';
import * as consumerApi from '../../services/consumerApi';
import { notifyError } from './notificationActions';

const REFRESH_TIMEOUT = 3600 * 1000;

export function setBrandData(data) {
    return { type: types.BRAND_SET_DATA, data };
}

export function setBrandLoading(isLoading) {
    return { type: types.BRAND_SET_LOADING, isLoading };
}

export function brandReset() {
    return { type: types.BRAND_RESET };
}

export function executeGetBrands() {
    return function (dispatch, getState) {
        const { auth, brand } = getState();
        const country = auth.user && auth.user.market;
        const { isLoading, brands, ts } = brand;
        const timeout = Date.now() - ts > REFRESH_TIMEOUT;
        const refresh =
            (!isLoading && brands == null) || (!isLoading && timeout);
        return !refresh
            ? Promise.resolve()
            : Promise.resolve()
                  .then(() => dispatch(setBrandLoading(true)))
                  .then(() => consumerApi.getBrandData(country))
                  .then((result) => dispatch(setBrandData(result)))
                  .catch(() =>
                      dispatch(notifyError((L) => L.msg_brand_data_fetch_error))
                  )
                  .finally(() => dispatch(setBrandLoading(false)));
    };
}
