// User Data class

import { Email } from './Email';
import { isString } from './typeUtils';

const CLAIM_READ_ONLY = 'app:read_only';
const CLAIM_CONSUMER_UPDATE = 'app:consumer_update';
const CLAIM_HAV_UPDATE = 'app:hav_update';

export class UserData {
    _claims;

    email;
    markets;
    market;

    hasReadOnly;
    hasConsumerUpdate;
    hasHavUpdate;
    hasAccess;

    constructor(token) {
        this._claims = {};

        this._processClaim(token, CLAIM_READ_ONLY);
        this._processClaim(token, CLAIM_CONSUMER_UPDATE);
        this._processClaim(token, CLAIM_HAV_UPDATE);

        this.email = new Email(token?.email);
        this.markets = Object.keys(this._claims).sort();
        this.market = null;
    }

    setMarket(market) {
        if (this.market && this.market === market) return false;

        this.market = this.markets.includes(market)
            ? market
            : this.markets[0] || null;

        this.hasReadOnly = this._hasClaim(CLAIM_READ_ONLY);
        this.hasConsumerUpdate = this._hasClaim(CLAIM_CONSUMER_UPDATE);
        this.hasHavUpdate = this._hasClaim(CLAIM_HAV_UPDATE);

        this.hasAccess =
            this.hasReadOnly || this.hasConsumerUpdate || this.hasHavUpdate;

        return true;
    }

    _processClaim(token, claim) {
        const value = token && claim && token[claim];
        const markets = (isString(value) && value.split(',')) || [];
        markets.forEach((market) => {
            this._claims[market] = this._claims[market] || new Set();
            this._claims[market].add(claim);
        });
    }

    _hasClaim(claim) {
        const hasClaim =
            claim &&
            this.market &&
            this._claims[this.market] &&
            this._claims[this.market].has(claim);
        return hasClaim || false;
    }
}
